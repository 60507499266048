<template>
  <v-card class="quick-entry">
    <v-card-title>
      Quick Entry

      <!-- save status -->
      <icon-value
        v-if="(!allAccordionsClosed && savedTextStatus) || (showSaveStatus && updateEncounterIndex > 0)"
        :icon="isUpdateComplete ? icons.mdiCheckCircleOutline : icons.mdiDotsCircle"
        :class="isUpdateComplete ? '' : 'spin'"
        color="secondary"
        style="width: 110px; margin-left: 10px"
      >
        {{ savedTextStatus ? (isUpdateComplete ? 'Saved' : 'Pending...') : '' }}
      </icon-value>
      <v-spacer></v-spacer>

      <!-- clear search/filters -->
      <btn
        label="Reset Search/Filters"
        color="secondary"
        :icon="icons.mdiUndoVariant"
        @click="resetSearchFilters"
      ></btn>
    </v-card-title>

    <v-expand-transition>
      <div
        v-show="!isFiltersSet"
        style="height: 60px"
      >
        <alert class="mx-5">
          Facility and Visit Date must be selected to create a communication log.
        </alert>
      </div>
    </v-expand-transition>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="quickEntryEncounters"
      item-key="id"
      :expanded.sync="expanded"
      :page.sync="isFiltersSet ? 1 : pagination.page"
      :items-per-page="isFiltersSet ? -1 : pagination.itemsPerPage"
      :sort-by.sync="sort.by"
      :sort-desc.sync="sort.desc"
      hide-default-footer
      show-select
      show-expand
      rem-single-expand
      :class="`has-pagination ${allAccordionsClosed ? 'accordion-opened' : ''}`"
      @item-expanded="encounterToggleExpand"
      @current-items="getFiltered"
      @page-count="pagination.pageCount = $event"
    >
      <template #top>
        <v-row class="mx-4 mt-2 pb-1">
          <!-- first name -->
          <text-field
            v-model="search.first_name"
            :append-icon="icons.mdiMagnify"
            label="First Name"
            class="col-sm-4 px-1"
          ></text-field>
          <!-- last name -->
          <text-field
            v-model="search.last_name"
            :append-icon="icons.mdiMagnify"
            label="Last Name"
            class="col-sm-4 px-1"
          ></text-field>
          <!-- date of birth -->
          <date-input
            v-model="search.dob_us"
            :append-icon="icons.mdiMagnify"
            label="Date of Birth"
            class="col-sm-4 px-1"
            rules="dob"
          ></date-input>
        </v-row>

        <v-row class="mx-4 mt-0 pb-1">
          <!-- facility filter -->
          <select-box
            v-model="search.facility"
            :items="facilities"
            label="Facility"
            class="col-sm-6 px-1"
            :class="!search.facility && 'required-field'"
          ></select-box>
          <!-- gender filter -->
          <select-box
            v-model="search.gender"
            :items="genders"
            label="Gender"
            class="col-sm-3 px-1"
          ></select-box>
          <!-- last 4 of ssn -->
          <text-field
            v-model="search.ssn_last_4"
            :append-icon="icons.mdiMagnify"
            label="SSN"
            mask="####"
            placeholder="####"
            class="col-sm-3 px-1"
          ></text-field>
        </v-row>

        <v-row class="mx-4 mt-0 pb-1">
          <!-- visit date last 14 days -->
          <checkbox
            v-model="search.last_fourteen_days"
            class="col-sm-1 px-0"
            label="Last 14"
            hide-details
            stack
            @click="if (search.last_fourteen_days) search.visit_date = null"
          ></checkbox>
          <!-- visit date -->
          <date-picker
            v-model="search.visit_date"
            label="Visit Date"
            :max="$date().format('YYYY-MM-DD')"
            class="col-sm-3p25 px-1"
            :class="!search.visit_date && 'required-field'"
            clearable
            @input="if (search.visit_date) search.last_fourteen_days = false"
          ></date-picker>
          <!-- visit type filter -->
          <select-box
            v-model="search.visit_type"
            :items="visitTypes"
            label="Type of Visit"
            class="col-sm-3 px-1"
          ></select-box>
          <!-- log status -->
          <select-box
            v-model="search.logStatus"
            :items="logStatus"
            label="Log Status"
            class="col-sm-2p5 px-1"
          ></select-box>
          <select-box
            v-model="search.synced"
            :items="query"
            label="Synced"
            class="col-sm-2p25 px-1"
          ></select-box>
        </v-row>

        <!-- icon help -->
        <v-expand-transition>
          <v-row
            v-show="showHelp"
            class="help-icons"
          >
            <div class="secondary--text">
              Icon Help:
            </div>
            <!--
            <icon-value
              :icon="icons.mdiChevronDoubleDown"
              :class="allAccordionsClosed ? 'flip' : ''"
              color="secondary"
            >
              {{ allAccordionsClosed ? 'Collapse All' : 'Expand All' }}
            </icon-value>
            <icon-value
              :icon="icons.mdiChevronDown"
              color="secondary"
            >
              Expand
            </icon-value>
            -->
            <icon-value
              :icon="icons.mdiClipboardPlusOutline"
              color="secondary"
            >
              New Treatment
            </icon-value>
            <!--
            <icon-value
              :icon="icons.mdiCheckboxBlankOutline"
              color="secondary"
            >
              Select
            </icon-value>
            -->
            <icon-value
              :icon="icons.mdiDecagramOutline"
              color="secondary"
            >
              Validated
            </icon-value>
            <icon-value
              :icon="icons.mdiEmailOutline"
              color="secondary"
            >
              Log Status
            </icon-value>
            <icon-value
              :icon="icons.mdiDotsVertical"
              color="secondary"
            >
              Action Menu
            </icon-value>
            <icon-value
              :icon="icons.mdiTableRowPlusAfter"
              color="secondary"
            >
              Add Wound
            </icon-value>
            <v-icon @click="showHelp = !showHelp">
              {{ icons.mdiClose }}
            </v-icon>
          </v-row>
        </v-expand-transition>
      </template>

      <!-- header overrides -->
      <template #header.data-table-expand>
        <v-icon
          :class="allAccordionsClosed ? 'flip' : ''"
          @click="toggleAllAccordions(allAccordionsClosed)"
        >
          {{ icons.mdiChevronDoubleDown }}
        </v-icon>
      </template>
      <template #header.data-table-select>
        <v-checkbox
          v-model="selectAllStatus"
          :indeterminate="selectAllIndeterminate"
          :off-icon="icons.mdiCheckboxMultipleBlankOutline"
          :on-icon="icons.mdiCheckboxMultipleMarked"
          :indeterminate-icon="icons.mdiMinusBoxMultiple"
          hide-details
          color="secondary"
          :disabled="!isFiltersSet || !filtered.length"
          @click="selectAllToggle"
        ></v-checkbox>
      </template>
      <template #header.is_quick_entry_validated>
        <v-icon>{{ icons.mdiDecagramOutline }}</v-icon>
      </template>
      <template #header.communication_log.log_status>
        <v-icon>{{ icons.mdiEmailOutline }}</v-icon>
      </template>
      <template #header.is_synced>
        <v-icon>{{ icons.mdiCloudOutline }}</v-icon>
      </template>
      <template #header.actions>
        <v-icon
          color="warning"
          @click="showHelp = !showHelp"
        >
          {{ icons.mdiInformationOutline }}
        </v-icon>
      </template>

      <!-- table data overrides -->
      <template #item.data-table-select="{ item }">
        <v-checkbox
          v-model="selected"
          :value="item"
          hide-details
          color="secondary"
          :disabled="!isFiltersSet || !item.is_quick_entry_validated"
          @click.stop="updateSelectStatus"
        ></v-checkbox>
      </template>

      <!-- first name -->
      <template #item.first_name="{ item }">
        <div class="table-no-wrap">
          {{ item.first_name }}
        </div>
      </template>

      <!-- last name -->
      <template #item.last_name="{ item }">
        <div class="table-no-wrap">
          {{ item.last_name }}
        </div>
      </template>

      <!-- visit date w/edit -->
      <template #item.visit_date="{ item }">
        <!-- 20231013 JMC: Disabling this since we don't really want users to create communication logs that are not within the same day of the visit. -->
        <!--        <date-picker-->
        <!--          v-if="canEditEncounter(item)"-->
        <!--          v-model="item.visit_date"-->
        <!--          :max="$date().format('YYYY-MM-DD')"-->
        <!--          class="input-font-size-14"-->
        <!--          hide-details-->
        <!--          no-outline-->
        <!--          required-->
        <!--          style="max-width: 100px"-->
        <!--          @blur="updateTreatmentsVisitDate(item)"-->
        <!--        ></date-picker>-->
        <!--        <span v-else>-->
        <!--          {{ $date(item.visit_date).format('MM/DD/YYYY') }}-->
        <!--        </span>-->
        {{ $date(item.visit_date).format('MM/DD/YYYY') }}
      </template>

      <!-- facility w/edit -->
      <template #item.place_of_service_id="{ item }">
        <select-box
          v-if="canEditEncounter(item)"
          v-model="item.place_of_service_id"
          :items="facilities"
          class="input-font-size-14"
          clearable="false"
          hide-details
          no-outline
          not-attached
          required
          @input="updateTreatmentsFacility(item)"
        ></select-box>
        <span
          v-else
          class="table-no-wrap"
        >
          {{ $store.getters['facilities/getById'](item.place_of_service_id).title }}
        </span>
      </template>

      <!-- visit type -->
      <template #item.visit_type="{ item }">
        <span
          v-if="item.visit_type"
          class="visit-type"
          :class="item.visit_type.toLowerCase()"
        >
          {{ item.visit_type === 'Follow-up' ? 'F/U' : item.visit_type }}
        </span>
      </template>

      <!-- Validated -->
      <template #item.is_quick_entry_validated="{ item }">
        <v-icon
          :color="item.is_quick_entry_validated || item.is_validated ? 'success' : 'error'"
        >
          {{ item.is_quick_entry_validated || item.is_validated ? icons.mdiCheckDecagram : icons.mdiCloseCircle }}
        </v-icon>
      </template>

      <!-- Log status -->
      <template #item.communication_log.log_status="{ item }">
        <v-icon
          :color="logStatusColor(item.communication_log)"
        >
          {{ logStatusIcon(item.communication_log) }}
        </v-icon>
      </template>

      <!-- Synced -->
      <template #item.is_synced="{ item }">
        <v-icon
          :color="item.is_synced ? 'success' : 'error'"
        >
          {{ item.is_synced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle }}
        </v-icon>
      </template>

      <!-- actions -->
      <template #item.actions="{item}">
        <v-menu
          transition="slide-y-transition"
          offset-y
          left
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ attrs['aria-expanded'] === 'true' ? icons.mdiClose : icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="!item.communication_log_id"
              @click="communicationLogDetails(item)"
            >
              <icon-value
                :disabled="!item.communication_log_id"
                :icon="icons.mdiEmailSearch"
              >
                Communication Log Details
              </icon-value>
            </v-list-item>

            <v-list-item
              :disabled="item.is_deceased || item.visit_date === today"
              @click="createQuickEntryFromEncounter(item)"
            >
              <icon-value
                :disabled="item.is_deceased || item.visit_date === today"
                :icon="icons.mdiClockPlus"
              >
                Create New Quick Entry
              </icon-value>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="viewEncounter(item)">
              <icon-value :icon="icons.mdiAccountEye">
                View/Edit Encounter
              </icon-value>
            </v-list-item>

            <v-list-item @click="editPatient(item)">
              <icon-value :icon="icons.mdiAccountEdit">
                Edit This Patient
              </icon-value>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <!-- expanded data -->
      <template #expanded-item="{ item }">
        <!--
          The getPatient() method can be CPU intense, and was being accessed many times
          in the section below. So this for "loop" is a trick to only run the getPatient()
          once per encounter. There's only one item in the array, so it only renders once,
          but it allows the use of the "patient" value to be used without overhead.
        -->
        <td
          v-for="(patient) in [getPatient(item.patient_id)]"
          :key="patient.id"
          :colspan="headers.length"
          class="expanded"
          style="padding: 0 !important"
        >
          <v-expand-transition>
            <v-form
              v-if="transitionTrigger && transitioned[item.id]"
              class="wounds-container"
              :disabled="!canEditEncounter(item)"
            >
              <div
                v-if="!canEditEncounter(item)"
                style="margin: 6px 6px 0 6px"
              >
                <alert
                  color="info"
                  class="col-sm-12 mb-0 py-1"
                >
                  Encounter already signed, values cannot be changed.
                </alert>
              </div>
              <!-- general patient information -->
              <v-row>
                <text-field
                  v-model="item.quick_entry_room"
                  label="Room"
                  counter="50"
                  class="col-sm-1p25"
                  hide-details
                  not-clearable
                  @keyup="updateEncounter(item, patient)"
                ></text-field>
                <text-area
                  v-model="item.quick_entry_notes"
                  label="Notes"
                  class="col-sm-5"
                  rows="1"
                  hide-details
                  not-clearable
                  @keyup="updateEncounter(item, patient)"
                ></text-area>
                <text-area
                  v-model="item.quick_entry_notes_to_nurse"
                  label="Notes to Nurse"
                  class="col-sm-5"
                  rows="1"
                  hide-details
                  not-clearable
                  @keyup="updateEncounter(item, patient)"
                ></text-area>
                <div
                  class="col-sm-0p75"
                  style="text-align: center"
                >
                  <btn
                    class="add-wound"
                    :icon="addNewWound === item.id ? icons.mdiClose : icons.mdiTableRowPlusAfter"
                    :disabled="patient.is_deceased || !canEditEncounter(item)"
                    :color="addNewWound === item.id ? 'secondary' : 'primary'"
                    @click="newWoundPracticeType = null;
                            addNewWound = addNewWound === item.id ? false : item.id"
                  ></btn>
                </div>
              </v-row>

              <!-- Add new wound location -->
              <v-expand-transition>
                <div
                  v-if="addNewWound === item.id"
                  class="new-wound"
                >
                  <radio-buttons
                    v-model="newWoundPracticeType"
                    :items="woundTypes"
                    label="Select wound type for new wound location"
                    hide-details
                    row
                    @input="addWound(item, patient, newWoundPracticeType)"
                  ></radio-buttons>
                </div>
              </v-expand-transition>

              <!-- wound location -->
              <div
                v-for="(wound, index) in patient.wounds"
                :key="`wound-${index}`"
                :ref="`wound-${wound.id}`"
                class="wound"
              >
                <div v-if="woundExistsNow(wound, item.visit_date, item.id)">
                  <!--
                    The latestTreatment() method can be CPU intense, and was being accessed many times
                    in the section below. So this "loop" is a trick to only run the latestTreatment()
                    once per wound. There's only one item in the array, so it only renders once, but
                    it allows the use of the "treatment" value to be used without additional overhead.
                    -->
                  <div
                    v-for="(treatment) in [latestTreatment(wound, item.visit_date, item.id)]"
                    :key="treatment.id"
                  >
                    <!-- wound header -->
                    <v-row class="wound-header">
                      <div class="col-sm-0p75 text-value">
                        <v-icon
                          v-if="!isNewTreatment(treatment, item.id) && canEditEncounter(item)"
                          class="expand-wound"
                          color="warning"
                          @click="addTreatment(item, patient, wound, treatment, wound.id)"
                        >
                          {{ icons.mdiClipboardPlusOutline }}
                        </v-icon>
                        <v-icon
                          v-if="isNewTreatment(treatment, item.id)"
                          :disabled="!treatment.is_quick_note_validated"
                          class="expand-wound"
                          @click="toggleWound(wound.id)"
                        >
                          {{ icons.mdiChevronDown }}
                        </v-icon>
                      </div>

                      <div class="col-sm-2 practice-type-title text-value">
                        #{{ wound.wound_number }}
                        {{ $store.getters['baseData/practiceTypeFromId'](wound.practice_type_id).title }}
                        <v-icon
                          v-if="wound.practice_type_id === 1
                            && treatment.wound_treatment
                            && treatment.wound_treatment.infection_signs
                            && treatment.wound_treatment.infection_signs.some(x => x.title !== 'None')"
                          color="error"
                        >
                          {{ icons.mdiBiohazard }}
                        </v-icon>
                      </div>

                      <div
                        :class="`col-sm-1p5 text-value ${
                          isNewTreatment(treatment, item.id)
                            ? (treatment.is_quick_note_validated ? 'success--text' : 'error--text')
                            : ''}`"
                      >
                        {{ formattedStatus(treatment) }}
                      </div>

                      <!-- wound location only for wound and derm treatments, and only editable for first treatment -->
                      <text-field
                        v-if="wound.practice_type_id !== 5 && isFirstTreatment(wound) && isNewTreatment(treatment, item.id)"
                        v-model="wound.location.location_text"
                        label="Location"
                        class="col-sm-3"
                        not-clearable
                        hide-details
                        label-top
                        required
                        @keyup="updateEncounter(item, patient, true)"
                      ></text-field>

                      <!-- Show previous wound/derm treatment details -->
                      <div
                        v-if="wound.practice_type_id !== 5 && (!isFirstTreatment(wound) || !isNewTreatment(treatment, item.id))"
                        class="col-sm-3 text-value"
                      >
                        {{ formattedLocation(wound) }}
                      </div>

                      <!-- etiology only for wound and derm treatments -->
                      <select-box
                        v-if="wound.practice_type_id !== 5 && isNewTreatment(treatment, item.id)"
                        v-model="treatment.etiology"
                        :items="wound.practice_type_id === 1 ? woundEtiologies : dermEtiologies"
                        label="Etiology"
                        item-value="title"
                        clearable="false"
                        class="col-sm-4"
                        dense
                        hide-details
                        label-top
                        not-attached
                        required
                        @change="
                          // if ($event === 'Other') collapseWound(wound.id);
                          updateEncounterImmediate(item, patient, true)"
                      ></select-box>

                      <!-- show previous wound/derm treatment details -->
                      <div
                        v-if="wound.practice_type_id !== 5 && !isNewTreatment(treatment, item.id)"
                        class="col-sm-4 text-value"
                      >
                        {{ treatment.etiology }}
                        <span v-if="treatment.etiology === 'Other'">
                          - {{ treatment.etiology_other }}
                        </span>
                        <div
                          v-if="wound.practice_type_id === 1
                            && treatment.wound_treatment
                            && treatment.wound_treatment.wound_treatment_wound_size"
                        >
                          {{ treatment.wound_treatment.wound_treatment_wound_size.wound_size_text }}
                        </div>
                        <div
                          v-if="wound.practice_type_id"
                          class="no-wrap"
                        >
                          {{ $store.getters['baseData/procedureFromId'](treatment.procedure_id) }}
                        </div>
                      </div>

                      <!-- g-tube treatments -->
                      <select-box
                        v-if="wound.practice_type_id === 5
                          && treatment.g_tube_treatment
                          && isNewTreatment(treatment, item.id)"
                        v-model="treatment.g_tube_treatment.abdominal_exam"
                        :items="abdominalExams"
                        label="Abdominal Exam"
                        :class="treatment.g_tube_treatment.abdominal_exam === 'Other' ? 'col-sm-2p25' : 'col-sm-7'"
                        clearable="false"
                        hide-details
                        label-top
                        not-attached
                        @change="updateEncounterImmediate(item, patient)"
                      ></select-box>
                      <text-field
                        v-if="wound.practice_type_id === 5
                          && treatment.g_tube_treatment
                          && treatment.g_tube_treatment.abdominal_exam === 'Other'
                          && isNewTreatment(treatment, item.id)"
                        v-model="treatment.g_tube_treatment.abdominal_exam_other"
                        label="Other Abdominal Exam"
                        class="col-sm-4p75"
                        not-clearable
                        hide-details
                        label-top
                        @keyup="updateEncounter(item, patient)"
                      ></text-field>

                      <!-- Show previous g-tube treatment details -->
                      <div
                        v-if="wound.practice_type_id === 5
                          && treatment.g_tube_treatment
                          && !isNewTreatment(treatment, item.id)"
                        class="col-sm-3 text-value"
                      >
                        {{ treatment.g_tube_treatment.abdominal_exam }}
                        <span v-if="treatment.g_tube_treatment.abdominal_exam === 'Other'">
                          - {{ treatment.g_tube_treatment.abdominal_exam_other }}
                        </span>
                      </div>
                      <div
                        v-if="wound.practice_type_id === 5
                          && treatment.g_tube_treatment
                          && !isNewTreatment(treatment, item.id)"
                        class="col-sm-4 text-value"
                      >
                        {{ treatment.g_tube_treatment.replacement_tube_type }}
                        <span v-if="treatment.g_tube_treatment.balloon_cc">
                          / {{ treatment.g_tube_treatment.balloon_cc }}
                        </span>
                        <span v-if="treatment.g_tube_treatment.replacement_size">
                          / {{ treatment.g_tube_treatment.replacement_size }}
                        </span>
                        <div
                          v-if="wound.practice_type_id === 5"
                          class="no-wrap"
                        >
                          {{ $store.getters['baseData/procedureFromId'](treatment.procedure_id) }}
                        </div>
                      </div>

                      <!-- Wound specific menu -->
                      <div
                        class="col-sm-0p75"
                        style="text-align: center"
                      >
                        <v-menu
                          transition="slide-y-transition"
                          offset-y
                          left
                        >
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>{{ attrs['aria-expanded'] === 'true' ? icons.mdiClose : icons.mdiDotsVertical }}</v-icon>
                            </v-btn>
                          </template>
                          <v-list>

                            <!-- View wound history -->
                            <v-list-item
                              :disabled="wound.practice_type_id != 1"
                              @click="openWoundHxModal(wound)"
                            >
                              <icon-value
                                :icon="icons.mdiHistory"
                              >
                                View History
                              </icon-value>
                            </v-list-item>

                            <!-- Delete treatment/wound -->
                            <v-list-item
                              v-if="treatment.can_be_deleted && canEditEncounter(item)"
                              @click="deleteTreatment(item, patient, wound, treatment)"
                            >
                              <icon-value
                                :icon="icons.mdiTrashCan"
                                color="error"
                              >
                                Delete Entry?
                              </icon-value>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                    </v-row>

                    <v-expand-transition>
                      <text-field
                        v-if="treatment.etiology === 'Other' && isNewTreatment(treatment, item.id)"
                        v-model="treatment.etiology_other"
                        label="Other Etiology"
                        class="etiology-other col-sm-6p75"
                        not-clearable
                        hide-details
                        label-top
                        required
                        @keyup="updateEncounter(item, patient, true)"
                      ></text-field>
                    </v-expand-transition>

                    <!-- wound details -->
                    <div
                      v-if="wound.practice_type_id === 1 && treatment.wound_treatment && treatment.wound_treatment.wound_treatment_wound_size"
                      class="wound-details"
                    >
                      <v-expand-transition>
                        <div
                          v-show="(validatePrimaryDims(treatment.wound_treatment.wound_treatment_wound_size) || validatePostDebridDims(treatment.wound_treatment.wound_treatment_wound_size))"
                        >
                          <alert color="warning">
                            ALL dimension fields must be populated or cleared.
                          </alert>
                        </div>
                      </v-expand-transition>

                      <div class="wound-container">
                        <v-card-actions>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.primary_length"
                            label="Pre Length"
                            numeric
                            :mask="measurementMask"
                            suffix="cm"
                            rules="numberRange(0,100)"
                            not-clearable
                            hide-details
                            label-top
                            :required="validatePrimaryDims(treatment.wound_treatment.wound_treatment_wound_size)"
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.primary_length = treatment.wound_treatment.wound_treatment_wound_size.primary_length ? Number(treatment.wound_treatment.wound_treatment_wound_size.primary_length).toFixed(1) : null"
                            @keyup="calcWoundSize(treatment, item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.primary_width"
                            label="Pre Width"
                            numeric
                            :mask="measurementMask"
                            suffix="cm"
                            rules="numberRange(0,100)"
                            not-clearable
                            hide-details
                            label-top
                            :required="validatePrimaryDims(treatment.wound_treatment.wound_treatment_wound_size)"
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.primary_width = treatment.wound_treatment.wound_treatment_wound_size.primary_width ? Number(treatment.wound_treatment.wound_treatment_wound_size.primary_width).toFixed(1) : null"
                            @keyup="calcWoundSize(treatment, item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.primary_depth"
                            label="Pre Depth cm"
                            numeric
                            :mask="treatment.wound_treatment.wound_treatment_wound_size.primary_depth !== 'UTD' ? measurementMask : false"
                            rules="numberRangeUTD(0,100)"
                            :append-icon="treatment.wound_treatment.wound_treatment_wound_size.primary_depth !== 'UTD' ? icons.mdiTimelineQuestion : null"
                            :not-clearable="treatment.wound_treatment.wound_treatment_wound_size.primary_depth !== 'UTD'"
                            hide-details
                            label-top
                            :required="validatePrimaryDims(treatment.wound_treatment.wound_treatment_wound_size)"
                            @click:append="setWoundSizePrimaryDepth(treatment, 'UTD', item, patient)"
                            @click:clear="setWoundSizePrimaryDepth(treatment, null, item, patient)"
                            @blur="woundSizePrimaryDepth(treatment)"
                            @keyup="calcWoundSize(treatment, item, patient)"
                          ></text-field>
                          <div>&nbsp;&nbsp;</div>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_length"
                            label="Post Length"
                            numeric
                            :mask="measurementMask"
                            suffix="cm"
                            rules="numberRange(0,100)"
                            not-clearable
                            hide-details
                            label-top
                            :required="validatePostDebridDims(treatment.wound_treatment.wound_treatment_wound_size)"
                            :disabled="isProcedureDebrid(treatment) || !treatment.procedure_id ? false : true"
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_length = treatment.wound_treatment.wound_treatment_wound_size.post_debridement_length ? Number(treatment.wound_treatment.wound_treatment_wound_size.post_debridement_length).toFixed(1) : null"
                            @keyup="calcDebridArea(treatment); updateEncounter(item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_width"
                            label="Post Width"
                            numeric
                            :mask="measurementMask"
                            suffix="cm"
                            rules="numberRange(0,100)"
                            not-clearable
                            hide-details
                            label-top
                            :required="validatePostDebridDims(treatment.wound_treatment.wound_treatment_wound_size)"
                            :disabled="isProcedureDebrid(treatment) || !treatment.procedure_id ? false : true"
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_width = treatment.wound_treatment.wound_treatment_wound_size.post_debridement_width ? Number(treatment.wound_treatment.wound_treatment_wound_size.post_debridement_width).toFixed(1) : null"
                            @keyup="calcDebridArea(treatment); updateEncounter(item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth"
                            label="Post Depth cm"
                            numeric
                            :mask="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth !== 'UTD' ? measurementMask : false"
                            rules="numberRangeUTD(0,100)"
                            :append-icon="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth !== 'UTD' ? icons.mdiTimelineQuestion : null"
                            :not-clearable="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth !== 'UTD'"
                            hide-details
                            label-top
                            :required="validatePostDebridDims(treatment.wound_treatment.wound_treatment_wound_size)"
                            :disabled="isProcedureDebrid(treatment) || !treatment.procedure_id ? false : true"
                            @click:append="setWoundSizePostDepth(treatment, 'UTD', item, patient)"
                            @click:clear="setWoundSizePostDepth(treatment, null, item, patient)"
                            @blur="woundSizePostDepth(treatment)"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <!--                           20231004 JMC: Disabling this for now since this is not in the provided template.-->
                          <!--                          <text-field-->
                          <!--                            v-model="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_percent"-->
                          <!--                            label="Debridement"-->
                          <!--                            numeric-->
                          <!--                            suffix="%"-->
                          <!--                            mask="###"-->
                          <!--                            rules="numberRange(1,100)"-->
                          <!--                            not-clearable-->
                          <!--                            hide-details-->
                          <!--                            label-top-->
                          <!--                            @keyup="calcDebridArea(treatment); updateEncounter(item, patient)"-->
                          <!--                          ></text-field>-->
                        </v-card-actions>

                        <v-card-actions>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.slough"
                            label="Slough"
                            numeric
                            mask="###"
                            rules="numberRange(0,100)"
                            suffix="%"
                            not-clearable
                            hide-details
                            label-top
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.slough = treatment.wound_treatment.wound_treatment_wound_size.slough ? parseInt(treatment.wound_treatment.wound_treatment_wound_size.slough) : null"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.granulation"
                            label="Granulation"
                            numeric
                            mask="###"
                            rules="numberRange(0,100)"
                            suffix="%"
                            not-clearable
                            hide-details
                            label-top
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.granulation = treatment.wound_treatment.wound_treatment_wound_size.granulation ? parseInt(treatment.wound_treatment.wound_treatment_wound_size.granulation) : null"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.necrotic_tissue"
                            label="Necrotic"
                            numeric
                            mask="###"
                            rules="numberRange(0,100)"
                            suffix="%"
                            not-clearable
                            hide-details
                            label-top
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.necrotic_tissue = treatment.wound_treatment.wound_treatment_wound_size.necrotic_tissue ? parseInt(treatment.wound_treatment.wound_treatment_wound_size.necrotic_tissue) : null"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.hyper_granulation"
                            label="Hyper granulation"
                            numeric
                            mask="###"
                            rules="numberRange(0,100)"
                            suffix="%"
                            not-clearable
                            hide-details
                            label-top
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.hyper_granulation = treatment.wound_treatment.wound_treatment_wound_size.hyper_granulation ? parseInt(treatment.wound_treatment.wound_treatment_wound_size.hyper_granulation) : null"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.eschar"
                            label="Eschar"
                            numeric
                            rules="numberRange(0,100)"
                            mask="###"
                            suffix="%"
                            not-clearable
                            hide-details
                            label-top
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.eschar = treatment.wound_treatment.wound_treatment_wound_size.eschar ? parseInt(treatment.wound_treatment.wound_treatment_wound_size.eschar) : null"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.epithelial"
                            label="Epithelial"
                            numeric
                            rules="numberRange(0,100)"
                            mask="###"
                            suffix="%"
                            not-clearable
                            hide-details
                            label-top
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.epithelial = treatment.wound_treatment.wound_treatment_wound_size.epithelial ? parseInt(treatment.wound_treatment.wound_treatment_wound_size.epithelial) : null"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <text-field
                            v-model="treatment.wound_treatment.wound_treatment_wound_size.dermal"
                            label="Dermal"
                            numeric
                            mask="###"
                            rules="numberRange(0,100)"
                            suffix="%"
                            not-clearable
                            hide-details
                            label-top
                            @blur="treatment.wound_treatment.wound_treatment_wound_size.dermal = treatment.wound_treatment.wound_treatment_wound_size.dermal ? parseInt(treatment.wound_treatment.wound_treatment_wound_size.dermal) : null"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                        </v-card-actions>

                        <v-row>
                          <select-box
                            v-model="treatment.procedure_id"
                            :items="woundProcedures"
                            label="Procedure Performed"
                            class="col-sm-6 fix-overflow"
                            dense
                            hide-details
                            label-top
                            not-attached
                            @change="clearPostMeasurements(treatment);updateEncounter(item, patient)"
                          ></select-box>
                          <select-box
                            v-model="treatment.wound_treatment.infection_signs"
                            :items="infectionSigns"
                            label="Signs of Infection"
                            class="col-sm-6 fix-overflow"
                            dense
                            multiple
                            hide-details
                            label-top
                            return-object
                            not-attached
                            distinctive-none
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                        </v-row>

                        <v-row>
                          <select-box
                            v-if="treatment.operative_note_wizard"
                            v-model="treatment.operative_note_wizard.consent_selected"
                            :items="consentOptions"
                            label="Consent"
                            class="col-sm-3p5"
                            dense
                            hide-details
                            label-top
                            not-attached
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                          <text-area
                            v-model="treatment.wound_treatment.wound_description_summary"
                            label="Wound Description"
                            class="col-sm-8p5"
                            rows="1"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-area>
                        </v-row>

                        <v-row>
                          <text-area
                            v-model="treatment.qent_notes"
                            label="Notes"
                            class="col-sm-6p5"
                            rows="1"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-area>
                          <text-area
                            v-model="treatment.current_treatment"
                            label="Current Treatment"
                            class="col-sm-6p5"
                            rows="1"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-area>
                        </v-row>
                      </div>
                    </div>

                    <!-- derm details -->
                    <div
                      v-if="wound.practice_type_id === 6 && treatment.derm_treatment"
                      class="wound-details"
                    >
                      <div class="wound-container">
                        <v-row>
                          <select-box
                            v-model="treatment.derm_treatment.lesion_conditions"
                            :items="lesionConditions"
                            label="Lesion Conditions"
                            class="col-sm-6 fix-overflow"
                            dense
                            multiple
                            hide-details
                            label-top
                            return-object
                            not-attached
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                          <text-field
                            v-if="treatment.derm_treatment.lesion_conditions ? treatment.derm_treatment.lesion_conditions.some(x => x.title === 'Other') : false"
                            v-model="treatment.derm_treatment.lesion_conditions_other"
                            label="Other lesion conditions..."
                            label-top
                            class="col-sm-6"
                          ></text-field>
                        </v-row>
                        <v-row>
                          <select-box
                            v-model="treatment.derm_treatment.topical_agent"
                            :items="topicalAgents"
                            item-value="title"
                            item-text="title"
                            label="Topical Agent"
                            class="col-sm-7"
                            :clearable="treatment.derm_treatment.secondary_topical_agent ? 'false' : null"
                            hide-details
                            label-top
                            not-attached
                            @click:clear="treatment.derm_treatment.topical_agent_strength = null"
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                          <text-field
                            v-model="treatment.derm_treatment.topical_agent_strength"
                            class="col-sm-5"
                            not-clearable
                            hide-details
                            label-top
                            label="Topical Agent Strength"
                            :placeholder="!treatment.derm_treatment.topical_agent ? 'Select agent to specify strength' : ''"
                            :disabled="!treatment.derm_treatment.topical_agent"
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                        </v-row>

                        <v-expand-transition>
                          <v-row v-if="treatment.derm_treatment.topical_agent">
                            <select-box
                              v-model="treatment.derm_treatment.secondary_topical_agent"
                              :items="topicalAgents"
                              item-value="title"
                              item-text="title"
                              label="Secondary Topical Agent (optional)"
                              class="col-sm-7"
                              hide-details
                              label-top
                              not-attached
                              @click:clear="treatment.derm_treatment.secondary_topical_agent_strength = null"
                              @change="updateEncounter(item, patient)"
                            ></select-box>
                            <text-field
                              v-model="treatment.derm_treatment.secondary_topical_agent_strength"
                              class="col-sm-5"
                              not-clearable
                              hide-details
                              label-top
                              label="Secondary Topical Agent Strength"
                              :placeholder="!treatment.derm_treatment.secondary_topical_agent ? 'Select agent to specify strength' : ''"
                              :disabled="!treatment.derm_treatment.secondary_topical_agent"
                              @keyup="updateEncounter(item, patient)"
                            ></text-field>
                          </v-row>
                        </v-expand-transition>

                        <v-row>
                          <text-area
                            v-model="treatment.derm_treatment.lesion_description"
                            label="Lesion Description"
                            class="col-sm-6"
                            rows="1"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-area>
                          <select-box
                            v-model="treatment.derm_treatment.duration"
                            :items="durations"
                            label="Duration"
                            class="col-sm-6"
                            hide-details
                            label-top
                            not-attached
                            dense
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                        </v-row>

                        <v-row>
                          <select-box
                            v-model="treatment.procedure_id"
                            :items="dermProcedures"
                            label="Procedure Performed"
                            class="col-sm-12"
                            dense
                            hide-details
                            label-top
                            not-attached
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                        </v-row>

                        <v-row>
                          <text-area
                            v-model="treatment.qent_notes"
                            label="Notes"
                            class="col-sm-6p5"
                            rows="1"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-area>
                          <text-area
                            v-model="treatment.current_treatment"
                            label="Current Treatment"
                            class="col-sm-6p5"
                            rows="1"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-area>
                        </v-row>
                      </div>
                    </div>

                    <!-- g-tube details -->
                    <div
                      v-if="wound.practice_type_id === 5 && treatment.g_tube_treatment"
                      class="wound-details"
                    >
                      <div class="wound-container">
                        <v-row>
                          <select-box
                            v-model="treatment.g_tube_treatment.g_tube_type"
                            :items="gTubeTypes"
                            label="G-Tube Type"
                            :class="treatment.g_tube_treatment.g_tube_type === 'Other' ? 'col-sm-3' : 'col-sm-4'"
                            hide-details
                            label-top
                            not-attached
                            @click:clear="
                              treatment.g_tube_treatment.g_tube_type_other = null;
                              treatment.g_tube_treatment.peri_tube_finding = null;
                              treatment.g_tube_treatment.peri_tube_tract = null"
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                          <text-field
                            v-if="treatment.g_tube_treatment.g_tube_type === 'Other'"
                            v-model="treatment.g_tube_treatment.g_tube_type_other"
                            label="Other G-tube Type"
                            class="col-sm-3"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <select-box
                            v-model="treatment.g_tube_treatment.peri_tube_finding"
                            :items="periTubeFindings"
                            label="Peri Tube Finding"
                            :class="treatment.g_tube_treatment.g_tube_type === 'Other' ? 'col-sm-3' : 'col-sm-4'"
                            clearable="false"
                            hide-details
                            label-top
                            not-attached
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                          <select-box
                            v-model="treatment.g_tube_treatment.peri_tube_tract"
                            :items="periTubeTracts"
                            label="Peri Tube Tract"
                            :class="treatment.g_tube_treatment.g_tube_type === 'Other' ? 'col-sm-3' : 'col-sm-4'"
                            clearable="false"
                            hide-details
                            label-top
                            not-attached
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                        </v-row>

                        <v-row>
                          <select-box
                            v-model="treatment.g_tube_treatment.replacement_tube_type"
                            :items="gTubeTypes"
                            label="Replacement Tube Type"
                            :class="treatment.g_tube_treatment.replacement_tube_type === 'Other' ? 'col-sm-3' : 'col-sm-4'"
                            hide-details
                            label-top
                            not-attached
                            @click:clear="
                              treatment.g_tube_treatment.replacement_tube_type_other = null;
                              treatment.g_tube_treatment.balloon_cc = null;
                              treatment.g_tube_treatment.replacement_size = null"
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                          <text-field
                            v-if="treatment.g_tube_treatment.replacement_tube_type === 'Other'"
                            v-model="treatment.g_tube_treatment.replacement_tube_type_other"
                            label="Other Replacement Tube Type"
                            class="col-sm-3"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-field>
                          <select-box
                            v-model="treatment.g_tube_treatment.balloon_cc"
                            :items="balloonCc"
                            label="Replacement Balloon Size cc"
                            :class="treatment.g_tube_treatment.replacement_tube_type === 'Other' ? 'col-sm-3' : 'col-sm-4'"
                            clearable="false"
                            hide-details
                            label-top
                            not-attached
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                          <select-box
                            v-model="treatment.g_tube_treatment.replacement_size"
                            :items="replacementSize"
                            label="Replacement Size French"
                            :class="treatment.g_tube_treatment.replacement_tube_type === 'Other' ? 'col-sm-3' : 'col-sm-4'"
                            clearable="false"
                            hide-details
                            label-top
                            not-attached
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                        </v-row>

                        <v-row>
                          <select-box
                            v-model="treatment.g_tube_treatment.replacement_reason"
                            :items="replacementReasons"
                            label="Replacement Reason"
                            class="col-sm-6"
                            hide-details
                            label-top
                            not-attached
                            dense
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                          <select-box
                            v-model="treatment.g_tube_treatment.duration"
                            :items="durations"
                            label="Duration"
                            class="col-sm-6"
                            hide-details
                            label-top
                            not-attached
                            dense
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                        </v-row>

                        <v-row>
                          <select-box
                            v-model="treatment.procedure_id"
                            :items="gtubeProcedures"
                            label="Procedure Performed"
                            class="col-sm-12"
                            hide-details
                            label-top
                            not-attached
                            dense
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                        </v-row>

                        <v-expand-transition>
                          <v-row v-if="treatment.g_tube_treatment.replacement_reason === 'Other'">
                            <text-area
                              v-model="treatment.g_tube_treatment.replacement_reason_other"
                              label="Other replacement reason"
                              class="col-sm-12"
                              rows="1"
                              hide-details
                              label-top
                              @keyup="updateEncounter(item, patient)"
                            ></text-area>
                          </v-row>
                        </v-expand-transition>

                        <v-row>
                          <select-box
                            v-model="treatment.g_tube_treatment.verification_of_placement_1"
                            :items="verificationOfPlacements"
                            label="Verification of Placement"
                            class="col-sm-12 hundred-percent"
                            hide-details
                            label-top
                            not-attached
                            dense
                            @change="updateEncounter(item, patient)"
                          ></select-box>
                        </v-row>

                        <v-expand-transition>
                          <v-row v-if="treatment.g_tube_treatment.verification_of_placement_1">
                            <select-box
                              v-model="treatment.g_tube_treatment.verification_of_placement_2"
                              :items="verificationOfPlacements"
                              label="Secondary Verification of Placement (optional)"
                              class="col-sm-6"
                              hide-details
                              label-top
                              not-attached
                              dense
                              @change="updateEncounter(item, patient)"
                            ></select-box>
                            <select-box
                              v-model="treatment.g_tube_treatment.verification_of_placement_3"
                              :items="verificationOfPlacements"
                              label="Tertiary Verification of Placement (optional)"
                              class="col-sm-6"
                              hide-details
                              label-top
                              not-attached
                              dense
                              @change="updateEncounter(item, patient)"
                            ></select-box>
                          </v-row>
                        </v-expand-transition>

                        <v-expand-transition>
                          <v-row
                            v-if="treatment.g_tube_treatment.verification_of_placement_1 === 'Other'
                              || treatment.g_tube_treatment.verification_of_placement_2 === 'Other'
                              || treatment.g_tube_treatment.verification_of_placement_3 === 'Other'"
                          >
                            <text-area
                              v-model="treatment.g_tube_treatment.verification_of_placement_other"
                              label="Other verification of placement..."
                              class="col-sm-12"
                              rows="1"
                              hide-details
                              label-top
                              @keyup="updateEncounter(item, patient)"
                            ></text-area>
                          </v-row>
                        </v-expand-transition>

                        <v-row>
                          <text-area
                            v-model="treatment.qent_notes"
                            label="Notes"
                            class="col-sm-6p5"
                            rows="1"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-area>
                          <text-area
                            v-model="treatment.current_treatment"
                            label="Current Treatment"
                            class="col-sm-6p5"
                            rows="1"
                            not-clearable
                            hide-details
                            label-top
                            @keyup="updateEncounter(item, patient)"
                          ></text-area>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </v-expand-transition>
        </td>
      </template>
    </v-data-table>

    <!-- pagination -->
    <v-expand-transition>
      <div
        v-show="!isFiltersSet"
        style="height: 78px"
      >
        <pagination
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.itemsPerPage"
          :page-count="pagination.pageCount"
        ></pagination>
      </div>
    </v-expand-transition>

    <div
      v-if="isFiltersSet"
      class="pb-5"
    ></div>

    <v-card-actions>
      <btn
        v-if="isFiltersSet"
        :label="isSelectedNewLog() ? 'Create Communication Log' : 'Resend Communication Log'"
        :icon="icons.mdiEmailArrowRight"
        :disabled="!selected.length"
        offline-disable
        color="success"
        @click="openCommModal"
      ></btn>
      <v-spacer></v-spacer>
      <btn
        label="Create Quick Entry"
        :icon="icons.mdiClockPlus"
        @click="createQuickEntryModalState = true"
      ></btn>
    </v-card-actions>

    <!-- log detail modal -->
    <v-dialog
      v-model="detailModalState"
      width="550"
      persistent
    >
      <v-card>
        <v-card-title class="pb-0">
          Communication Log Details
        </v-card-title>

        <NoteSection>
          <text-field
            :value="logDetails.log_status"
            label="Communication Log Status"
            class="col-sm-6 mt-3"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-field
            :value="$date(logDetails.created).format('MM/DD/YYYY hh:mm A')"
            label="Sent On"
            class="col-sm-6 mt-3"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-field
            :value="$store.getters['facilities/getById'](logDetails.place_of_service_id).title"
            label="Facility"
            class="col-sm-8"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-field
            :value="$date(logDetails.visit_date).format('MM/DD/YYYY')"
            label="Visit Date"
            class="col-sm-4"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-field
            :value="logDetails.password"
            label="Password for Communication Log"
            class="col-sm-12"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-area
            :value="logDetails.contact_email || ' '"
            label="Email Recipients"
            class="col-sm-12"
            readonly
          ></text-area>
        </NoteSection>

        <v-card-actions>
          <!-- cancel -->
          <btn
            label="Close"
            color="secondary"
            :icon="icons.mdiClose"
            @click="detailModalState = false"
          ></btn>
          <v-spacer></v-spacer>
          <btn
            label="Resend Log"
            color="error"
            :icon="icons.mdiEmailArrowRight"
            @click="resendCommunicationLog"
          ></btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- create quick entry modal -->
    <v-dialog
      v-model="createQuickEntryModalState"
      width="750"
      persistent
    >
      <v-card>
        <v-form v-model="formValid">
          <patient-profile-find
            title="Search Patients / Create Quick Entry"
            quick-entry
            :facility="search.facility"
            :modal-state.sync="createQuickEntryModalState"
            @selected="createQuickEntryFromPatient($event)"
          ></patient-profile-find>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- create communication log modal -->
    <v-dialog
      v-model="commModalState"
      width="650"
      persistent
    >
      <v-card>
        <v-form v-model="formValid">
          <v-card-title class="pb-0">
            {{ isSelectedNewLog() ? 'Create Communication Log' : 'Resend Communication Log' }}

            <v-spacer></v-spacer>

            <!-- reset -->
            <btn
              label="Reset Values"
              color="secondary"
              :icon="icons.mdiUndoVariant"
              @click="openCommModal"
            ></btn>
          </v-card-title>

          <NoteSection>
            <text-field
              :value="facility.title"
              label="Facility"
              class="col-sm-8 mt-3"
              disabled
            ></text-field>
            <text-field
              :value="$date(search.visit_date).format('MM/DD/YYYY')"
              label="Visit Date"
              class="col-sm-4 mt-3"
              disabled
            ></text-field>
            <text-area
              :value="communicationLog.patients"
              label="Patients Included in Communication Log"
              class="col-sm-12"
              rows="1"
              readonly
              no-click
            ></text-area>
            <text-field
              v-model="communicationLog.password"
              label="Password for Communication Log"
              class="col-sm-7"
              readonly
              password
              :password-visible.sync="communicationLog.isPasswordVisible"
              @focus="$event.target.blur()"
            ></text-field>
            <btn
              label="Generate New Password"
              color="warning"
              class="col-sm-5"
              :icon="icons.mdiLockReset"
              @click="generateNewPassword"
            ></btn>
            <v-combobox
              v-model="communicationLog.recipients"
              :items="communicationLog.emailOptions"
              :rules="computedRules"
              :delimiters="[',', ' ']"
              label="Email Recipients"
              counter="10"
              hide-selected
              outlined
              multiple
              chips
              deletable-chips
              @change="recipientsChanged"
            ></v-combobox>
          </NoteSection>

          <alert class="mx-5">
            <p>
              Are you sure you wish to create the communication log and email it to the listed recipients at {{ facility.title }}?
            </p>
            Warning: This operation cannot be undone!
          </alert>

          <v-card-actions>
            <!-- cancel -->
            <btn
              label="Cancel"
              color="secondary"
              :icon="icons.mdiCancel"
              @click="commModalState = false; communicationLog.isPasswordVisible = false"
            ></btn>

            <v-spacer></v-spacer>

            <!-- send -->
            <btn
              :label="isSelectedNewLog() ? 'Create Log' : 'Resend Log'"
              color="error"
              :icon="icons.mdiEmailArrowRight"
              :disabled="!formValid"
              offline-disable
              @click="createCommunicationLog"
            ></btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <div class="wound-history-modal" v-if="selectedWound">
      <!-- Wound History Modal -->
      <wound-history-modal
        :selected-wound="selectedWound"
        :wound-hx-modal-state.sync="woundHxModalState"
      ></wound-history-modal>
    </div>

  </v-card>
</template>

<script>
/* eslint-disable no-param-reassign */

import PatientProfileFind from '@/components/patients/Find.vue'
import WoundHistoryModal from '@/components/features/WoundHistoryModal.vue'
import '@/components/patients/primitives'
import SyncEncounters from '@/mixins/SyncEncounters'
import {
  mdiAccountClock, mdiAccountEdit,
  mdiAccountEye,
  mdiAccountMultiple,
  mdiAlertDecagram,
  mdiBiohazard,
  mdiCancel,
  mdiCheckCircleOutline,
  mdiCheckDecagram,
  mdiCheckboxBlankOutline,
  mdiCheckboxMultipleBlankOutline,
  mdiCheckboxMultipleMarked,
  mdiChevronDoubleDown,
  mdiChevronDown,
  mdiClipboardAlert,
  mdiClipboardPlusOutline,
  mdiClockPlus,
  mdiClose,
  mdiCloseCircle,
  mdiCloudCheckVariant, mdiCloudOutline,
  mdiContentSaveOutline,
  mdiDecagramOutline,
  mdiDotsCircle,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiEmailArrowRight, mdiEmailCheck,
  mdiEmailOutline,
  mdiEmailSearch,
  mdiInformationOutline,
  mdiLockReset,
  mdiMagnify,
  mdiMinusBoxMultiple, mdiTableRowPlusAfter, mdiTableRowRemove,
  mdiTimelineQuestion,
  mdiTrashCan,
  mdiUndoVariant,
  mdiHistory,
} from '@mdi/js'
import dayjs from 'dayjs'
import compact from 'lodash/compact'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters, mapState } from 'vuex'

const initialSearch = {
  last_fourteen_days: false,
  visit_date: null,
  visit_date_us: null,
  facility: null,
  visit_type: null,
  validated: null,
  logStatus: null,
  synced: null,
  ssn_last_4: null,
  first_name: null,
  last_name: null,
  gender: null,
  dob: null,
  dob_us: null,
}

const initialState = {
  by: 'last_name',
  desc: false,
}

export default { // Methods: syncEncounters(), syncCorePatients(), syncFullPatients(), syncingProgress()
  components: { PatientProfileFind, WoundHistoryModal },
  mixins: [SyncEncounters],
  data() {
    return {
      woundHxModalState: false,
      selectedWound: {},
      quickEntryEncounters: {},
      patientCache: [],
      expandedWounds: [],
      transitionTrigger: 0,
      transitioned: [],
      dialogVisitDate: null,
      dialogFacility: null,
      measurementMask: [/[0-9|.]/, /[0-9|.]/, /[0-9|.]/, /[0-9|.]/, /[0-9|.]/],
      formValid: false,
      detailModalState: false,
      commModalState: false,
      createQuickEntryModalState: false,
      communicationLog: {
        password: null,
        emailOptions: [],
        recipients: [],
        patients: null,
        isPasswordVisible: false,
      },
      logDetails: {},
      logDetailEncounterId: null,
      search: {
        ...initialSearch,
        visit_date: this.$date().format('YYYY-MM-DD'),
      },
      filtered: [],
      selected: [],
      selectAllStatus: false,
      selectAllIndeterminate: false,
      sort: { ...initialState },
      userId: this.$authUser.userId(),
      icons: {
        mdiCloseCircle,
        mdiMagnify,
        mdiUndoVariant,
        mdiCheckDecagram,
        mdiEmailArrowRight,
        mdiEmailCheck,
        mdiEmailAlert,
        mdiTimelineQuestion,
        mdiCancel,
        mdiClose,
        mdiDotsVertical,
        mdiAccountClock,
        mdiAccountEdit,
        mdiEmailSearch,
        mdiClockPlus,
        mdiTrashCan,
        mdiEmailOutline,
        mdiDecagramOutline,
        mdiClipboardPlusOutline,
        mdiInformationOutline,
        mdiChevronDown,
        mdiCloudCheckVariant,
        mdiCloudOutline,
        mdiBiohazard,
        mdiAlertDecagram,
        mdiClipboardAlert,
        mdiAccountMultiple,
        mdiLockReset,
        mdiCheckCircleOutline,
        mdiAccountEye,
        mdiCheckboxMultipleBlankOutline,
        mdiCheckboxMultipleMarked,
        mdiMinusBoxMultiple,
        mdiTableRowPlusAfter,
        mdiTableRowRemove,
        mdiChevronDoubleDown,
        mdiContentSaveOutline,
        mdiDotsCircle,
        mdiCheckboxBlankOutline,
        mdiHistory,
      },
      showHelp: false,
      headers: [
        {
          text: 'First',
          value: 'first_name',
          align: 'name',
          filter: value => !this.search.first_name || value.toLowerCase().indexOf(this.search.first_name.trim().toLowerCase()) === 0,
        },
        {
          text: 'Last',
          value: 'last_name',
          align: 'name',
          filter: value => !this.search.last_name || value.toLowerCase().indexOf(this.search.last_name.trim().toLowerCase()) === 0,
        },
        {
          text: 'Visit Date',
          value: 'visit_date',
          filter: value => (!this.search.visit_date && !this.search.last_fourteen_days)
            || (!this.search.last_fourteen_days && value === this.search.visit_date)
            || (this.search.last_fourteen_days && dayjs().diff(value, 'days') <= 14),
        },
        {
          text: 'Facility',
          value: 'place_of_service_id',
          align: 'facility',
          filter: value => !this.search.facility || value === this.search.facility,
        },
        {
          text: 'Type',
          value: 'visit_type',
          align: 'small',
          filter: value => !this.search.visit_type || value === this.search.visit_type,
        },
        {
          text: 'Validated',
          value: 'is_quick_entry_validated',
          align: 'xsmall',
        },
        {
          text: 'Status',
          value: 'communication_log.log_status',
          align: 'xsmall',
          filter: value => !this.search.logStatus
            || value === this.search.logStatus
            || (this.search.logStatus === 'Unsent' && !value),
        },
        {
          text: 'Synced',
          value: 'is_synced',
          align: 'xsmall',
          filter: value => this.search.synced === null || value === this.search.synced,
        },
        {
          value: 'dob',
          align: 'hide',
          filter: value => !this.search.dob || value === this.search.dob || this.search.dob.length < 10,
        },
        {
          value: 'gender',
          align: 'hide',
          filter: value => !this.search.gender || value === this.search.gender,
        },
        {
          value: 'ssn_last_4',
          align: 'hide',
          filter: value => !this.search.ssn_last_4 || value === this.search.ssn_last_4 || this.search.ssn_last_4.length < 4,
        },
        {
          text: '',
          value: 'actions',
          align: 'xsmall',
          sortable: false,
        },
      ],
      consentOptions: [
        { title: 'Family' },
        { title: 'Patient' },
        { title: 'Patient conservator' },
        { title: 'Patient spouse' },
        { title: 'Two doctors' },
      ],
      woundTypes: [
        { title: 'Wound', id: 1 },
        { title: 'Dermatology', id: 6 },
        { title: 'G-Tube', id: 5 },
      ],
      expanded: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      queryDebounceTimer: null,
      encounterDebounceTimer: [],
      updateEncounterIndex: 0,
      isUpdateCompleteTimer: null,
      savedTextStatus: false,
      showSaveStatus: false,
      addNewWound: false,
      newWoundPracticeType: null,
      doNotCloseAccordion: false,
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
    ...mapGetters('auth', ['facilities']),
    ...mapGetters('baseData', [
      'woundProcedures',
      'dermProcedures',
      'gtubeProcedures',
      'balloonCc',
      'replacementSize',
    ]),
    ...mapGetters('encounters', [
      'isSynced',
      'genders',
      'visitTypes',
      'logStatus',
      'woundEtiologies',
      'dermEtiologies',
      'infectionSigns',
      'topicalAgents',
      'abdominalExams',
      'durations',
      'gTubeTypes',
      'periTubeFindings',
      'periTubeTracts',
      'replacementReasons',
      'verificationOfPlacements',
      'lesionConditions',
    ]),
    ...mapState('encounters', { encounters: 'items' }),
    ...mapState('patients', { patients: 'items' }),
    isFiltersSet() {
      return !!this.search.facility && !!this.search.visit_date
    },
    facility() {
      return this.$store.getters['facilities/getById'](this.search.facility)
    },
    computedRules() {
      let rulesArray = []
      rulesArray = rulesArray.concat(this.$validationRules.requiredEmail, this.$validationRules.maxEmails(5))

      return rulesArray
    },
    today() {
      return this.$date().format('YYYY-MM-DD')
    },
    allAccordionsClosed() {
      return !this.$custom.isEmpty(this.expanded)
    },
    isUpdateComplete() {
      // eslint-disable-next-line no-unused-expressions
      this.updateEncounterIndex
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const updateStatus = Object.keys(this.encounterDebounceTimer).length === 0

      clearTimeout(this.isUpdateCompleteTimer)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties, vue/no-async-in-computed-properties
      this.isUpdateCompleteTimer = setTimeout(() => {
        this.savedTextStatus = false
      }, 3000)

      return updateStatus
    },
  },
  watch: {
    'search.facility': {
      handler() {
        this.selectAllStatus = false
        this.selectAllIndeterminate = false
        this.selected = []
      },
    },
    'search.visit_date': {
      handler() {
        this.search.visit_date_us = this.search.visit_date !== null ? this.$date(this.search.visit_date).format('MM/DD/YYYY') : null
        this.selectAllStatus = false
        this.selectAllIndeterminate = false
        this.selected = []
      },
    },
    'search.dob_us': {
      handler() {
        this.search.dob = this.search.dob_us ? this.$date(this.search.dob_us).format('YYYY-MM-DD') : null
      },
    },
    search: {
      deep: true,
      handler() { this.updateQuery() },
    },
    pagination: {
      deep: true,
      handler() { this.updateQuery() },
    },
    sort: {
      deep: true,
      handler() { this.updateQuery() },
    },
  },
  beforeMount() {
    this.getQuickEntryRecords()
  },
  mounted() {
    // Query string sets search filters & pagination
    if (!this.$custom.isObjectEmpty(this.$route.query)) {
      this.search = {
        ...initialSearch,
        ...this.$route.query,
        facility: Number(this.$route.query.facility),
        signed: this.$custom.strToBoolNull(this.$route.query.signed),
        synced: this.$custom.strToBoolNull(this.$route.query.synced),
        last_fourteen_days: this.$custom.strToBoolNull(this.$route.query.last_fourteen_days),
      }
      this.sort.by = this.$route.query.by
      this.sort.desc = this.$custom.strToBoolNull(this.$route.query.desc)
      if (this.$route.query.itemsPerPage) {
        this.pagination.itemsPerPage = Number(this.$route.query.itemsPerPage)
      }
      if (this.$route.query.page) {
        this.$nextTick(() => {
          this.pagination.page = Number(this.$route.query.page)
        })
      }
    }
  },
  methods: {
    openWoundHxModal(woundObject) {
      this.selectedWound = woundObject
      this.woundHxModalState = true
    },

    isSelectedNewLog() {
      // Return true if none is selected.
      if (this.selected.length < 1) {
        return true
      }

      // Filter selected entries and if at least 1 entry has no log, it means that we're creating a new communication log.
      const noLogEntries = this.selected.filter(encounter => (
        !encounter.communication_log_id
      ))

      return noLogEntries.length > 0
    },
    getEncountersToSync() {
      return this.encounters.filter(e => (
        e.created_by_user_id === this.userId
        && !e.is_synced
      ))
    },
    getPatientsToSync() {
      return this.patients.filter(p => (
        p.updated
      ))
    },
    isProcedureDebrid(treatment) {
      const procedure = this.$store.getters['baseData/procedureFromId'](treatment.procedure_id)

      return procedure && procedure.toLowerCase().includes('debrid')
    },

    clearPostMeasurements(treatment) {
      this.$nextTick(() => {
        // Clear post measurements if procedure was changed to non-debridement.
        if (!this.isProcedureDebrid(treatment)) {
          treatment.wound_treatment.wound_treatment_wound_size.post_debridement_length = null
          treatment.wound_treatment.wound_treatment_wound_size.post_debridement_width = null
          treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth = null
          treatment.wound_treatment.wound_treatment_wound_size.post_debridement_percent = null
        }
      })
    },
    getQuickEntryRecords() {
      // Get the quick entry records for this user
      const quickEntryEncounters = this.encounters.filter(
        x => x.created_by_user_id === this.userId
          && x.deleted !== true
          && x.is_quick_entry === true
          && (x.visit_date === this.today || !this.$custom.isObjectEmpty(x.communication_log))
          && ((!this.$custom.isObjectEmpty(x.communication_log) ? x.communication_log.visit_date : null) === x.visit_date || this.$custom.isObjectEmpty(x.communication_log)),
      )

      // Populate possibly missing info from encounters
      quickEntryEncounters
        .filter(x => !x.last_name)
        .forEach(encounter => {
          const patient = this.getPatient(encounter.patient_id)
          encounter.first_name = patient.first_name
          encounter.last_name = patient.last_name
          encounter.gender = patient.gender
          encounter.ssn_last_4 = patient.ssn_last_4
          encounter.dob = patient.dob
          encounter.is_deceased = patient.is_deceased
        })
      quickEntryEncounters
        .filter(x => x.quick_entry_room === undefined || x.quick_entry_notes === undefined || x.quick_entry_notes_to_nurse === undefined)
        .forEach(encounter => {
          encounter.quick_entry_room = encounter.quick_entry_room ? encounter.quick_entry_room : ''
          encounter.quick_entry_notes = encounter.quick_entry_notes ? encounter.quick_entry_notes : ''
          encounter.quick_entry_notes_to_nurse = encounter.quick_entry_notes_to_nurse ? encounter.quick_entry_notes_to_nurse : ''
        })

      // 20240110: Doesn't seem to be necessary since consent_selected already defaults to null.
      // Populate possibly missing info from patients/wounds/treatments
      // quickEntryEncounters
      //   .filter(x => x.practice_type_id === 1)
      //   .forEach(encounter => {
      //     const patient = this.getPatient(encounter.patient_id)
      //     patient.wounds.forEach(wound => {
      //       wound.treatments
      //         .filter(x => !x.operative_note_wizard)
      //         .forEach(treatment => {
      //           // Only do this for treatments from unsigned encounters. Treatments from signed encounters should not be editable.
      //           if (!treatment.encounter.is_signed) {
      //             treatment.operative_note_wizard = { consent_selected: null }
      //           }
      //         })
      //     })
      //   })

      // Validate quick entry records
      quickEntryEncounters.forEach(encounter => {
        if (!encounter.is_signed) {
          const patient = this.getPatient(encounter.patient_id)
          const validated = this.checkValidation(patient, encounter.id, false)
          if (!validated && !this.communicationLogSuccessful(encounter)) {
            encounter.is_quick_entry_validated = false
            if (encounter.is_validated) {
              encounter.is_validated = false
              encounter.is_synced = false
            }
          } else if (validated && !encounter.is_quick_entry_validated) {
            encounter.is_quick_entry_validated = true
          }
        }
      })

      this.quickEntryEncounters = quickEntryEncounters
    },
    calcDebridArea(treatment) {
      const woundSize = treatment?.wound_treatment?.wound_treatment_wound_size
      if (woundSize
        && this.$custom.notEmpty(woundSize.post_debridement_length)
        && this.$custom.notEmpty(woundSize.post_debridement_width)
        && this.$custom.notEmpty(woundSize.post_debridement_percent)
      ) {
        woundSize.post_debridement_area_debrided = ((
          woundSize.post_debridement_length
          * woundSize.post_debridement_width
          * woundSize.post_debridement_percent
        ) / 100).toFixed(2)
      }
    },
    setWoundSizePrimaryDepth(treatment, value, item, patient) {
      treatment.wound_treatment.wound_treatment_wound_size = {
        ...treatment.wound_treatment.wound_treatment_wound_size,
        primary_depth: value,
      }
      this.calcWoundSize(treatment, item, patient, true)
    },
    setWoundSizePostDepth(treatment, value, item, patient) {
      treatment.wound_treatment.wound_treatment_wound_size = {
        ...treatment.wound_treatment.wound_treatment_wound_size,
        post_debridement_depth: value,
      }
      this.calcWoundSize(treatment, item, patient, true)
    },
    woundSizePrimaryDepth(treatment) {
      this.$nextTick(() => {
        if (treatment.wound_treatment.wound_treatment_wound_size.primary_depth !== 'UTD') {
          treatment.wound_treatment.wound_treatment_wound_size.primary_depth = treatment.wound_treatment.wound_treatment_wound_size.primary_depth
            ? Number(treatment.wound_treatment.wound_treatment_wound_size.primary_depth || 0).toFixed(1)
            : null
        }
      })
    },
    woundSizePostDepth(treatment) {
      this.$nextTick(() => {
        if (treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth !== 'UTD') {
          treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth = treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth
            ? Number(treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth || 0).toFixed(1)
            : null
        }
      })
    },
    calcWoundSize(treatment, item, patient, immediate = false) {
      const woundSize = treatment?.wound_treatment?.wound_treatment_wound_size
      if (woundSize) {
        woundSize.is_healed = false
        if (this.$custom.notEmpty(woundSize.primary_length) && this.$custom.notEmpty(woundSize.primary_width)) {
          woundSize.primary_area = (woundSize.primary_length * woundSize.primary_width).toFixed(2)
        }
        if (woundSize.primary_area === '0.00' && this.$custom.notEmpty(woundSize.primary_depth) && Number(woundSize.primary_depth).toFixed(1) === '0.0') {
          woundSize.is_healed = true
          woundSize.wound_size_text = 'Wound resolved'
        } else if (this.$custom.notEmpty(woundSize.primary_length) && this.$custom.notEmpty(woundSize.primary_width) && this.$custom.notEmpty(woundSize.primary_depth) && woundSize.primary_depth === 'UTD') {
          woundSize.wound_size_text = `${Number(woundSize.primary_length).toFixed(1)} cm x ${Number(woundSize.primary_width).toFixed(1)} cm x UTD`
        } else if (this.$custom.notEmpty(woundSize.primary_length) && this.$custom.notEmpty(woundSize.primary_width) && this.$custom.notEmpty(woundSize.primary_depth)) {
          woundSize.wound_size_text = `${Number(woundSize.primary_length).toFixed(1)} cm x ${Number(woundSize.primary_width).toFixed(1)} cm x ${Number(woundSize.primary_depth).toFixed(1)} cm`
        } else if (this.$custom.notEmpty(woundSize.primary_length) && this.$custom.notEmpty(woundSize.primary_width)) {
          woundSize.wound_size_text = `${Number(woundSize.primary_length).toFixed(1)} cm x ${Number(woundSize.primary_width).toFixed(1)} cm`
        } else {
          woundSize.wound_size_text = null
        }
        if (immediate) {
          this.updateEncounterImmediate(item, patient)
        } else {
          this.updateEncounter(item, patient)
        }
      }
    },
    addWound(encounter, patient, practiceType) {
      // Build the start of a new wound
      const woundId = uuidv4()
      const now = this.$custom.utcNow()
      const newWound = {
        // Changes here should also be duplicated in Wounds.vue, method `addWound`
        id: woundId,
        patient_id: patient.id,
        encounter_id: encounter.id,
        provider_user_id: this.$authUser.id(),
        practice_type_id: practiceType,
        wound_number: patient.wounds?.length > 0
          ? Math.max(...patient.wounds?.map(o => o.wound_number)) + 1
          : 1,
        is_healed: false,
        created: now,
        is_wound_facility_acquired: false,
        is_wound_not_recent: false,
        wound_acquired_facility_id: null,

        // Not in the DB, for Vue only
        place_of_service_id: encounter.place_of_service_id, // I don't believe this is being used, it's not in the DB
        can_be_deleted: true,
        followup_treatment: false,
        updated: true,

        treatments: [{
          id: uuidv4(),
          place_of_service_id: encounter.place_of_service_id,
          patient_id: patient.id,
          encounter_id: encounter.id,
          wound_id: woundId,
          etiology: null,
          etiology_other: null,
          procedure_id: null,
          current_treatment: null,
          qent_notes: null,
          operative_note: null,
          is_validated: false,
          is_quick_note_validated: practiceType === 5,
          created: now,
          encounter: {
            id: encounter.id,
            visit_date: encounter.visit_date,
          },

          // Not in the DB, for Vue only
          require_vitals: null,
          can_be_deleted: true,
        }],
      }

      // Add specifics for each practice type
      if (practiceType === 1) {
        newWound.location = {}
        newWound.treatments[0].wound_treatment = {
          wound_treatment_wound_size: { primary_depth: null },
        }
        newWound.treatments[0].operative_note_wizard = {}
      } else if (practiceType === 6) {
        newWound.location = {}
        newWound.treatments[0].derm_treatment = {}
      } else if (practiceType === 5) {
        newWound.treatments[0].g_tube_treatment = {}
      }
      newWound.updated = true

      // Add the new wound to the patient
      patient.wounds?.push(newWound)

      // If selected, uncheck the communication log checkbox for this encounter
      if (practiceType === 1 || practiceType === 6) {
        this.unselectEncounter(encounter.id)
      }

      this.$nextTick(() => {
        this.updateEncounterImmediate(encounter, patient, true)

        this.$nextTick(() => {
          // Reset add wound dropdown selector
          this.addNewWound = false
          this.newWoundPracticeType = null
          // Expand treatment if g-tube.
          if (practiceType === 5) this.expandWound(woundId)
          practiceType = null
        })
      })
    },
    async addTreatment(encounter, patient, wound, latestTreatment, woundId) {
      if (!this.isNewTreatment(latestTreatment, encounter.id)) {
        // Changes here should also be duplicated in Wounds.vue, method `addTreatment`

        // Check if the wound is healed
        if (wound.is_healed) {
          // Show confirmation dialog
          const result = await this.$root.confirm({
            title: 'Confirm Treatment Addition',
            body: 'This wound is healed. Are you sure you want to add a treatment?',
            confirm: 'Add a treatment',
            cancel: 'Cancel',
          })
          if (!result) {
            return // Exit if the user did not confirm adding treatment to a healed wound.
          }
        }

        // Find a etiology match...
        let etiology = null
        let etiologyOther = null
        if (latestTreatment) {
          etiology = latestTreatment.etiology
          etiologyOther = latestTreatment.etiology_other
          if (latestTreatment.etiology && (wound.practice_type_id === 1 || wound.practice_type_id === 6)) {
            const etiologies = wound.practice_type_id === 1 ? this.woundEtiologies : this.dermEtiologies
            if (etiologies.findIndex(x => x.title === etiology) === -1) {
              const etiologyIndex = etiologies.findIndex(x => x.title === this.$custom.toProperCaseAll(latestTreatment.etiology))
              if (etiologyIndex > -1) {
                etiology = etiologies[etiologyIndex].title
              } else if (etiology && !etiologyOther) {
                etiologyOther = etiology
                etiology = 'Other'
              }
            }
          }
        }

        const newTreatmentId = uuidv4()
        const newTreatment = {
          id: newTreatmentId,
          place_of_service_id: encounter.place_of_service_id,
          patient_id: patient.id,
          encounter_id: encounter.id,
          wound_id: wound.id,
          etiology,
          etiology_other: etiologyOther,
          procedure_id: null,
          current_treatment: null,
          qent_notes: null,
          operative_note: null,
          is_validated: false,
          is_quick_note_validated: true,
          created: this.$custom.utcNow(),
          encounter: {
            id: encounter.id,
            visit_date: encounter.visit_date,
          },

          // Not in the DB, for Vue only
          require_vitals: null,
          can_be_deleted: true,
          updated: true,
        }

        if (wound.practice_type_id === 1) {
          // Wound treatment
          newTreatment.wound_treatment = {
            wound_treatment_wound_size: { primary_depth: null },
          }

          // Pull in consent data from operative note wizard, mainly the consent info
          if (latestTreatment && latestTreatment.operative_note_wizard) {
            newTreatment.operative_note_wizard = {
              consent_selected: latestTreatment.operative_note_wizard?.consent_selected,
              consent_text: latestTreatment.operative_note_wizard.consent_text,
            }
          } else if (!latestTreatment) {
            newTreatment.operative_note_wizard = { consent_selected: null }
          }

          // Wound stage from pervious treatment carries forward
          if (latestTreatment) {
            const woundStage = latestTreatment?.wound_treatment?.wound_treatment_wound_size?.wound_stage
            if (woundStage) {
              newTreatment.wound_treatment.wound_treatment_wound_size.wound_stage = woundStage
            }
          }
        } else if (wound.practice_type_id === 6) {
          // Derm treatment
          newTreatment.derm_treatment = {}
        } else if (wound.practice_type_id === 5) {
          // G-Tube treatment
          newTreatment.g_tube_treatment = {}
        }

        const woundIndex = patient.wounds?.findIndex(x => x.id === woundId)
        patient.wounds[woundIndex].treatments.push(newTreatment)
        patient.wounds[woundIndex].followup_treatment = true // Not in the DB, for Vue only
        patient.wounds[woundIndex].is_healed = false // Mark the wound as not healed if the user adds a treatment
        patient.wounds[woundIndex].updated = true
        latestTreatment = newTreatment
        this.updateEncounterImmediate(encounter, patient, true)
        this.expandWound(woundId)
      }
    },
    deleteTreatment(encounter, patient, wound, treatment) {
      const treatmentIndex = wound.treatments.findIndex(x => x.id === treatment.id)
      if (treatmentIndex !== -1 && treatment.can_be_deleted) {
        const woundIndex = patient.wounds.findIndex(x => x.id === wound.id)
        const deleteWound = woundIndex !== -1 && wound.can_be_deleted
        this.$root.confirm({
          title: `Delete ${deleteWound ? 'Wound &' : ''} Treatment Record?`,
          subTitle: 'Warning: This operation cannot be undone!',
          body: `Are you sure you wish to delete this ${deleteWound ? 'wound and' : ' treatment'} record?`,
          confirm: 'Delete',
          confirmIcon: this.icons.mdiTrashCan,
          confirmColor: 'error',
        }).then(result => {
          if (result) {
            wound.treatments.splice(treatmentIndex, 1)
            treatment = null
            if (deleteWound) {
              patient.wounds.splice(woundIndex, 1)
              wound = null
            } else {
              this.collapseWound(wound.id)
            }
            this.$nextTick(() => {
              this.updateEncounterImmediate(encounter, patient, true)

              // Force Vue to re-render by toggling addNewWound property
              const prevAddNewWound = this.addNewWound
              this.addNewWound = 0
              this.$nextTick(() => {
                this.addNewWound = prevAddNewWound
              })
            })
          }
        })
      }
    },
    expandWound(id) {
      this.toggleWound(id, true)
    },
    collapseWound(id) {
      this.toggleWound(id, false)
    },
    toggleWound(id, expand = null) {
      if (expand === null) expand = !this.expandedWounds[id]
      this.$nextTick(() => {
        const woundRef = this.$refs[`wound-${id}`]
        if (woundRef && woundRef[0]) {
          const woundDetails = woundRef[0].querySelector('.wound-container')
          woundRef[0].className = expand ? 'wound expanded' : 'wound'
          if (woundDetails && (this.$custom.isEmpty(woundDetails.style.maxHeight) || woundDetails.style.maxHeight === (expand ? '0px' : 'fit-content'))) {
            woundDetails.setAttribute('style', `max-height: ${woundDetails.scrollHeight}px`)
            if (expand) {
              setTimeout(() => {
                woundDetails.setAttribute('style', 'max-height: fit-content')
                this.expandedWounds[id] = true
              }, 250)
            } else {
              setTimeout(() => {
                woundDetails.setAttribute('style', 'max-height: 0px')
                this.expandedWounds[id] = false
              }, 1)
            }
          }
        }
      })
    },
    toggleAllAccordions(state) {
      if (state) {
        // Close all encounters
        this.closeAllAccordions()
      } else {
        // Open all encounters
        this.expanded = this.quickEntryEncounters
        this.$nextTick(() => {
          this.quickEntryEncounters
            .forEach(encounter => {
              this.transitioned[encounter.id] = true
            })
          this.transitionTrigger += 1
        })
        this.showSaveStatus = true
      }
    },
    closeAllAccordions() {
      this.transitioned = []
      this.transitionTrigger += 1
      setTimeout(() => {
        this.expandedWounds = []
        this.addNewWound = false
        this.expanded = []
      }, 250)
    },
    encounterToggleExpand(data) {
      if (data.value) {
        // Encounter is being expanded
        this.showSaveStatus = true
        this.$nextTick(() => {
          this.transitioned[data.item.id] = true
          this.transitionTrigger += 1
        })
      } else {
        // Encounter is being collapsed
        delete this.transitioned[data.item.id]
        const patient = this.getPatient(data.item.patient_id)
        patient.wounds?.forEach(wound => {
          // if the wound was expanded, set it to closed
          if (this.expandedWounds[wound.id]) {
            this.expandedWounds[wound.id] = false
          }
        })
        if (this.addNewWound === data.item.id) this.addNewWound = false
      }
    },
    updateTreatmentsVisitDate(encounter) {
      const patient = this.getPatient(encounter.patient_id)
      patient.wounds?.forEach(wound => {
        const treatment = wound.treatments.filter(x => x.encounter_id === encounter.id && x.deleted !== true)
        if (treatment.length === 1) {
          treatment[0].encounter.visit_date = encounter.visit_date
        }
      })
      this.updateEncounterImmediate(encounter, patient)
    },
    updateTreatmentsFacility(encounter) {
      const patient = this.getPatient(encounter.patient_id)
      patient.place_of_service_id = encounter.place_of_service_id
      patient.wounds?.forEach(wound => {
        const treatment = wound.treatments.filter(x => x.encounter_id === encounter.id && x.deleted !== true)
        if (treatment.length === 1) {
          treatment[0].place_of_service_id = encounter.place_of_service_id
          treatment[0].updated = true
        }
      })
      this.$store.commit('patients/updateFacilityAcquiredWound', {
        id: patient.id,
        facility: encounter.place_of_service_id,
      })
      this.updateEncounterImmediate(encounter, patient)
    },
    updateEncounter(encounter, patient = null, checkValidation = false) {
      // Debounce updating encounter/patient
      clearTimeout(this.encounterDebounceTimer[encounter.id])
      this.savedTextStatus = true
      this.encounterDebounceTimer[encounter.id] = setTimeout(() => {
        this.updateEncounterImmediate(encounter, patient, checkValidation)
        delete this.encounterDebounceTimer[encounter.id]
      }, 750)
      this.updateEncounterIndex += 1
    },
    updateEncounterImmediate(encounter, patient = null, checkValidation = false) {
      // Wait for things to update first
      this.$nextTick(() => {
        if (checkValidation && patient) {
          encounter.is_quick_entry_validated = this.checkValidation(patient, encounter.id)
        }

        // Ensure the patient and its wounds exist
        if (patient?.wounds) {
          const woundsToUpdate = new Set();

          // Update treatments and track related wounds
          patient.wounds.forEach(wound => {
            wound.treatments?.forEach(treatment => {
              if (treatment.encounter?.id === encounter.id) {
                treatment.updated = true;
                woundsToUpdate.add(treatment.wound_id);
              }
            });
          });

          // Mark related wounds as updated
          patient.wounds.forEach(wound => {
            if (woundsToUpdate.has(wound.id)) {
              wound.updated = true;
            }
          });
        }

        this.doNotCloseAccordion = encounter.is_synced
        this.showSaveStatus = true
        this.savedTextStatus = true
        encounter.is_validated = false
        encounter.is_synced = false
        this.$store.commit('encounters/updateEncounter', { encounter, patient })
        if (patient) delete this.patientCache[patient.id]
        this.updateEncounterIndex += 1
      })
    },
    ///////////////////////////////
    // Validations //

    // Validates quick entry record
    checkValidation(patient, encounterId, unselect = true) {
      if (!patient.wounds.length) {
        if (unselect) this.unselectEncounter(encounterId)

        return false
      }

      let encounterValidated = true
      let woundsWithTreatmentCount = 0

      patient.wounds?.forEach(wound => {
        const treatment = wound.treatments.find(x => x.encounter_id === encounterId && x.deleted !== true)
        if (treatment) {
          woundsWithTreatmentCount += 1
          let treatmentValidated = true

          // Validation for wound and derm treatments
          if ((wound.practice_type_id === 1 || wound.practice_type_id === 6)
            && (!wound.location
              || this.$custom.isEmpty(wound.location.location_text)
              || this.$custom.isEmpty(treatment.etiology)
              || (treatment.etiology === 'Other' && this.$custom.isEmpty(treatment.etiology_other))
            )
          ) {
            treatmentValidated = false
            encounterValidated = false
          }

          treatment.is_quick_note_validated = treatmentValidated
        }
      })
      const isEntryValid = woundsWithTreatmentCount && encounterValidated
      if (unselect && !isEntryValid) this.unselectEncounter(encounterId)

      return isEntryValid
    },
    // Validates pre debridement wound dimension fields
    validatePrimaryDims(woundTreatmentSize) {
      const length = woundTreatmentSize.primary_length
      const width = woundTreatmentSize.primary_width
      const depth = woundTreatmentSize.primary_depth

      // Returns true when an array of variables is all populated or all blank
      const allTrueOrFalse = arr => arr.every(e => e) || arr.every(e => !e);
      return (!allTrueOrFalse([length, width, depth]))
    },
    // Validates post debridement wound dimension fields
    validatePostDebridDims(woundTreatmentSize) {
      const length = woundTreatmentSize.post_debridement_length
      const width = woundTreatmentSize.post_debridement_width
      const depth = woundTreatmentSize.post_debridement_depth

      // Returns true when an array of variables is all populated or all blank
      const allTrueOrFalse = arr => arr.every(e => e) || arr.every(e => !e);
      return (!allTrueOrFalse([length, width, depth]))
    },
    ///////////////////////////////

    woundExistsNow(wound, visitDate, encounterId) {
      /*
        If there's no previous or current treatments, but there's
        treatments in the future, this wound didn't exist at this time
      */
      return (this.latestTreatment(wound, visitDate, encounterId)
        || wound.treatments.findIndex(x => !x.deleted && x.encounter?.visit_date > visitDate) === -1
      )
    },
    latestTreatment(wound, visitDate, encounterId) {
      if (!wound.treatments.length) return false

      const treatment = wound.treatments.find(x => x.encounter_id === encounterId && !x.deleted)
      if (treatment) return treatment

      const treatments = wound.treatments
        .filter(x => !x.deleted && x.encounter?.visit_date < visitDate)
        .sort((a, b) => (a.encounter?.visit_date < b.encounter?.visit_date && 1) || -1)

      return (Array.isArray(treatments) && treatments.length) ? treatments[0] : false
    },
    isFirstTreatment(wound) {
      return wound.treatments.length === 1
    },
    isNewTreatment(treatment, encounterId) {
      return (!treatment || !treatment.encounter) ? false : encounterId === treatment.encounter_id
    },
    formattedStatus(treatment) {
      return treatment && treatment.encounter ? this.$date(treatment.encounter.visit_date).format('MM/DD/YYYY') : ''
    },
    formattedLocation(wound) {
      return wound.location ? wound.location.location_text : ''
    },
    formattedEtiology(treatment) {
      if (!treatment) return ''

      const { etiology } = treatment

      return etiology === 'Other' ? `Other - ${treatment.etiology_other}` : etiology
    },
    getFiltered(event) {
      this.filtered = event
      !this.doNotCloseAccordion
        ? this.closeAllAccordions()
        : this.doNotCloseAccordion = false
    },
    selectAllToggle() {
      if (this.selectAllStatus && !this.selectAllIndeterminate) {
        this.selected = this.filtered.filter(
          x => (
            x.is_quick_entry_validated
            || x.is_signed
          ),

          // && (
          //   !x.communication_log
          //   || (x.communication_log.log_status !== 'Initiated' && x.communication_log.log_status !== 'Sent')
          // ),
        )
      } else {
        this.selected = []
      }
      this.$nextTick(() => {
        this.updateSelectStatus()
      })
    },
    updateSelectStatus() {
      this.selectAllIndeterminate = !!this.selected.length && !!this.filtered.length && this.selected.length !== this.filtered.length
      this.selectAllStatus = !!this.selected.length && !this.selectAllIndeterminate
    },
    unselectEncounter(encounterID) {
      if (this.selected.length > 0) {
        const selectedId = this.selected.findIndex(x => x?.id === encounterID)
        if (selectedId > -1) this.selected.splice(selectedId, 1)
      }
    },
    updateQuery() {
      // Debounce query update
      clearTimeout(this.queryDebounceTimer)
      this.queryDebounceTimer = setTimeout(() => {
        // Replace the query
        const query = { ...this.search, ...this.sort, ...this.pagination }
        Object.keys(query).forEach(key => { if (query[key] === null || typeof query[key] === 'undefined') query[key] = '' })
        window.history.replaceState({}, null, `${this.$route.path}?${new URLSearchParams(query).toString()}`)
        this.$store.commit('route/updateQuery', query)
      }, 250)
    },
    resetSearchFilters() {
      this.search = {
        ...initialSearch,
        visit_date: this.$date().format('YYYY-MM-DD'),
      }
      this.sort = { ...initialState }
      this.showHelp = false
    },
    openCommModal() {
      this.communicationLog.isPasswordVisible = false
      this.communicationLog.password = this.facility.password
      this.communicationLog.emailOptions = this.$store.getters['auth/getQuickEntryEmailsById'](this.facility?.id)?.email_addresses?.split(',')
      this.communicationLog.emailOptions?.forEach((email, index) => {
        this.communicationLog.emailOptions[index] = email.trim()
      })
      this.communicationLog.recipients = this.communicationLog.emailOptions

      const patients = []
      this.selected.forEach(patient => {
        patients.push(`${patient.first_name} ${patient.last_name}`)
      })
      this.communicationLog.patients = patients.join(', ')

      this.commModalState = true
    },
    recipientsChanged() {
      let validEmail = true
      this.communicationLog.recipients.forEach((recipient, index) => {
        // Regex to validate email addresses
        // This pattern matches most common email formats, allowing:
        // - Local part: Alphanumeric characters, dots, underscores, plus signs, and hyphens
        // - Domain part: Alphanumeric characters, dots (not consecutive), and hyphens, ending with an alphanumeric character
        // - Top-level domain: At least two alphabetic characters (e.g., .eu, .com, .org)
        const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/

        if (!emailPattern.test(recipient)) {
          this.communicationLog.recipients.splice(index, 1)
          validEmail = false
        }
      })
      if (!validEmail) {
        this.$store.dispatch('notify', { value: 'Invalid email address entered.', color: 'error' })
      }
    },
    viewEncounter(item) {
      if ((item.is_signed && item.is_synced) || item.is_deferred_care) {
        // View signed surgical note
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](item.practice_type_id).noteRoute,
          query: { id: item.id, quickEntry: true },
        })
      } else if (item.created_by_user_id !== this.userId) {
        // Don't allow a different provider to edit an encounter
        this.$store.dispatch('notify', { value: 'You can\'t edit this encounter as you\'re not the provider who created it.', color: 'error' })
      } else {
        // Edit encounter note
        this.$store.dispatch('loading', true)
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](item.practice_type_id).encounterRoute,
          query: { id: item.id, quickEntry: true },
        })
      }
    },
    createQuickEntryFromEncounter(encounter) {
      this.$router.push({ name: 'create-encounter', query: { id: encounter.patient_id, quickEntry: true }, params: { practiceTypeId: 1, placeOfServiceId: this.facility.id } })
    },
    createQuickEntryFromPatient(patient) {
      let facilityId = this.facility.id
      if (patient.place_of_service_id !== this.facility.id) {
        facilityId = patient.place_of_service_id
      }

      this.$router.push({
        name: 'create-encounter',
        query: {
          id: patient.id,
          quickEntry: true
        },
        params: {
          practiceTypeId: 1,
          placeOfServiceId: facilityId
        }
      })
    },
    editPatient(item) {
      this.$router.push({ name: 'edit-patient', query: { id: item.patient_id } })
    },
    communicationLogDetails(item) {
      this.logDetails = item.communication_log
      this.logDetailEncounterId = item.id
      this.detailModalState = true
    },
    resendCommunicationLog() {
      this.detailModalState = false

      let encountersToSync = []
      const patientsToSync = []
      const encounterIds = [this.logDetailEncounterId]

      encountersToSync.push(this.$store.getters['encounters/getById'](this.logDetailEncounterId))
      patientsToSync.push(this.$store.getters['patients/getById'](encountersToSync[0].patient_id))

      // Retrieve all unsynced encounters (unrelated to the current comm log) linked to the patient IDs in patientsToSync.
      // We're pushing the entire patient record, including treatments,
      // but some encounters having those new treatments may not be in the communication log.
      patientsToSync.forEach(patient => {
        const otherEncounters = this.encounters.filter(e => (
          e.created_by_user_id === this.userId
          && !e.is_synced
          && e.patient_id === patient.id
        ))

        // Extend encountersToSync array
        otherEncounters.forEach(otherEncounter => {
          encountersToSync.push(otherEncounter)
        })
      })

      // De-dupe encountersToSync
      encountersToSync = [...new Set(compact(encountersToSync))]

      // Create communication log object
      const commLog = {
        created_by_user_id: this.$authUser.userId(),
        place_of_service_id: this.logDetails.place_of_service_id,
        visit_date: this.logDetails.visit_date,
        log_type: 'Update',
        password: this.logDetails.password,
        contact_email: this.logDetails.contact_email,
        encounter_ids: encounterIds,
      }

      // 20231019 JMC: Using this again since we want to allow users to edit a communication log if the encounter isn't signed yet.
      // This alternative up/down syncs everything, which I don't think is required to resend a communication log
      this.sendCommunicationLog(encountersToSync, patientsToSync, commLog)

      // Start the sync process
      // this.syncPos = 1
      // this.syncCnt = 2
      // this.syncingProgress()
      // this.$store.dispatch('encounters/syncingModal', true)
      // this.$store.dispatch('encounters/sendCommLog', {
      //   ...commLog,
      // }).then(commLogSuccess => {
      //   if (commLogSuccess) {
      //     this.$store.dispatch('notify', { value: 'Communication log resent.' })
      //   } else {
      //     this.errorDialog(commLogSuccess, 'Failed to send communication log!')
      //   }
      //   this.logCleanup()
      // })
    },
    createCommunicationLog() {
      this.commModalState = false

      // Filter the selected encounter array to be sure we're sending expected data
      let encountersToSync = this.selected.filter(encounter => (
        encounter.is_quick_entry
        && encounter.is_quick_entry_validated
        && encounter.created_by_user_id === this.$authUser.userId()
        && encounter.place_of_service_id === this.search.facility
        && encounter.visit_date === this.search.visit_date
      ))

      // Build patients to sync
      let patientsToSync = []
      let encounterIds = []
      encountersToSync.forEach(encounter => {
        delete encounter.can_be_deleted
        patientsToSync.push(this.$store.getters['patients/getById'](encounter.patient_id))
        encounterIds.push(encounter.id)
      })

      // Retrieve all unsynced encounters (unrelated to the current comm log) linked to the patient IDs in patientsToSync.
      // We're pushing the entire patient record, including treatments,
      // but some encounters having those new treatments may not be in the communication log.
      patientsToSync.forEach(patient => {
        const otherEncounters = this.encounters.filter(e => (
          e.created_by_user_id === this.userId
          && !e.is_synced
          && e.patient_id === patient.id
        ))

        // Extend encountersToSync array
        otherEncounters.forEach(otherEncounter => {
          encountersToSync.push(otherEncounter)
        })
      })

      // De-dupe arrays
      encountersToSync = [...new Set(compact(encountersToSync))]
      patientsToSync = [...new Set(compact(patientsToSync))]

      // The encounters associated with the communication log,
      // which may not match the total records in encountersToSync
      encounterIds = [...new Set(compact(encounterIds))]

      // Create communication log object
      const commLog = {
        created_by_user_id: this.$authUser.userId(),
        place_of_service_id: this.search.facility,
        visit_date: this.search.visit_date,
        log_type: this.isSelectedNewLog() ? 'Initial' : 'Update',
        password: this.communicationLog.password,
        contact_email: this.communicationLog.recipients.join(', '),
        encounter_ids: encounterIds,
      }

      this.sendCommunicationLog(encountersToSync, patientsToSync, commLog)
    },
    generateNewPassword() {
      this.communicationLog.isPasswordVisible = false
      this.$root.confirm({
        title: 'Generate New Communication Log Password?',
        html: true,
        body: `Are you sure you wish to generate a new communication log password for <b>${this.facility.title}</b>?<br><br>
               Note: The new password won't be saved till a new communication log is sent and won't work for previous communication logs.<br><br>`,
        confirm: 'Generate New Password',
        confirmColor: 'error',
        confirmIcon: this.icons.mdiLockReset,
      }).then(result => {
        if (result) {
          // Easily ambiguous numbers/letters removed (O and 0, l and 1, etc.) - 500 billion combinations (29^8)
          const validChars = '23456789abcdefghkmnprstuvwxyz'
          let newPassword = ''
          for (let i = 0; i < 8; i += 1) {
            const randomNumber = Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / 0x100000000) * validChars.length)
            newPassword += validChars.substring(randomNumber, randomNumber + 1)
          }
          this.communicationLog.isPasswordVisible = true
          this.communicationLog.password = newPassword
          this.$store.dispatch('notify', { value: 'New communication log password generated.' })
        }
      })
    },
    async sendCommunicationLog(encountersToSync, patientsToSync, commLog) {
      let message = ''
      let response = ''
      try {
        this.$store.commit('auth/updateQuickEntryEmails', {
          id: commLog.place_of_service_id, email_addresses: commLog.contact_email,
        })

        // 20240104: Disabling this again, since we are no longer doing a full sync.
        // We only need to sync the user's selected encounters.

        // We actually want to get all patients and encounters needed to be synced, otherwise,
        // those records (not included in the communication log) will be lost.
        // const patientsToSync = this.getPatientsToSync()
        // const encountersToSync = this.getEncountersToSync()

        // Reset the sync progress values
        this.syncPos = 0
        this.syncCnt = 5

        // Start the syncing process
        this.syncingProgress()
        this.$store.dispatch('encounters/syncingModal', true)
        console.log('----------- Comm Log Starting -----------')

        // Syncing Core Patients
        message = 'Patients Core'
        const patientCoreResponse = await this.syncCorePatients(patientsToSync)
        if (patientCoreResponse !== true) {
          response += patientCoreResponse
          response += '<hr><br>'
        }
        this.syncingProgress()
        console.log(`Syncing done for ${message}`)

        // Syncing Encounters
        message = 'Encounters'
        const encounterResponse = await this.syncEncounters(encountersToSync)
        if (encounterResponse !== true) {
          response += encounterResponse
          response += '<hr><br>'
        }
        this.syncingProgress()
        console.log(`Syncing done for ${message}`)

        // Syncing Full Patients
        message = 'Full Patients'
        const patientFullResponse = await this.syncFullPatients(patientsToSync)
        if (patientFullResponse !== true) {
          response += patientFullResponse
          response += '<hr><br>'
        }
        this.syncingProgress()
        console.log(`Syncing done for ${message}`)

        // Throw error if any sync failed before sending comms log.
        if (response !== '') {
          throw response
        }

        // Sending Comms Log
        message = 'Communication Log'
        this.$store.commit('encounters/SET_SYNCING_TXT', 'Sending Communication Log')
        const commLogResponse = await this.$store.dispatch('encounters/sendCommLog', {
          ...commLog,
        })
        console.log(commLogResponse)
        if (commLogResponse === true) {
          this.syncingProgress()
          console.log('Done posting communication log...')
          // Pull updated facilities' passwords
          this.$store.dispatch('facilities/loadFacilities')
          this.$store.dispatch('auth/refresh')
          console.log('---------- Comm Log Completed Successfully ----------')
          this.$store.dispatch('notify', { value: 'Communication log sent.' })
          this.logCleanup()
        } else {
          this.$store.dispatch('encounters/setSynced', false)
          throw response
        }
      } catch (responseError) {
        response = `<strong>Failed to send communication log:</strong><br><br>${responseError}`
        this.logCleanup()
        this.errorDialog(response)
      }
    },
    logCleanup() {
      this.getQuickEntryRecords()
      this.selected = []
      this.$store.dispatch('encounters/syncingModal', false)
      this.updateSelectStatus()
    },
    logStatusColor(communicationLog) {
      if (!communicationLog) return 'error'
      switch (communicationLog.log_status) {
        case 'Initiated': return 'warning'
        case 'Sent': return 'success'
        case 'Failed': return 'error'
        default: return 'error'
      }
    },
    logStatusIcon(communicationLog) {
      if (!communicationLog) return this.icons.mdiCloseCircle
      switch (communicationLog.log_status) {
        case 'Initiated': return this.icons.mdiEmailArrowRight
        case 'Sent': return this.icons.mdiEmailCheck
        case 'Failed': return this.icons.mdiEmailAlert
        default: return this.icons.mdiCloseCircle
      }
    },
    canEditEncounter(encounter) {
      return (!encounter.is_signed)
    },
    communicationLogSuccessful(encounter) {
      return encounter.communication_log?.log_status === 'Sent'
    },
    getPatient(patientId) {
      if (this.patientCache[patientId]) return this.patientCache[patientId]
      this.patientCache[patientId] = this.$store.getters['patients/getById'](patientId)

      return this.patientCache[patientId]
    },
  },
}
</script>

<style lang="scss">
.v-menu__content.v-small-dialog__menu-content {
  margin-top: -12px;
  .v-small-dialog__content {
    padding: 12px 10px 4px 10px;
    max-width: 400px;
  }
}
.quick-entry {
  .v-data-table.accordion-opened tbody tr:not(.v-data-table__expanded__content) {
    background-color: rgba(0, 0, 0, 0.08) !important;
    td {
      color: rgba(94, 86, 105, 0.95) !important;
    }
  }
  .v-data-table tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    cursor: default;
  }
  .v-input.v-text-field.no-outline {
    input,
    .v-select__selection {
      color: rgba(94, 86, 105, 0.68);
    }
  }
  .v-data-table tbody tr:not(.v-data-table__expanded__content) td {
    input,
    .v-select__selection {
      color: rgb(79, 72, 88) !important;
    }
  }
  .v-data-table__expanded__content td > div .v-input {
    font-weight: 400 !important;
  }
  .text-value {
    margin-top: -12px;
    .no-wrap {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
  .expanded {
    height: auto !important;
    .wounds-container {
      >.v-card__actions, >.row {
        padding: 9px 3px 4px 3px !important;
        margin: 0 !important;
        align-items: flex-start;
        >div {
          padding: 0 3px;
          .v-btn {
            &.v-btn--block {
              padding: 0;
            }
          }
        }
        .add-wound {
          min-width: unset;
          max-width: 44px;
          margin-left: -3px;
          .v-icon {
            margin: 0 auto;
          }
        }
        &:last-child {
          text-align: center;
        }
      }
    }
    .new-wound {
      margin-bottom: 0 !important;
      .radio-group-container {
        padding: 6px 0 6px 18px;
        >label {
          font-weight: 500;
          color: rgba(94, 86, 105, 0.95);
        }
      }
    }
    // Each wound row
    .wound {
      color: rgba(94, 86, 105, 0.95);
      font-weight: 500 !important;
      margin-bottom: 3px !important;
      border: 1px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      max-width: calc(100vw - 2rem);
      transition: margin 0.3s ease, padding 0.3s ease, border 0.3s ease, background-color 0.3s ease;
      &:last-child {
        border-bottom-color: transparent;
      }
      &.expanded {
        border: 1px solid grey;
        padding: 3px 0 3px 0;
        margin: -3px 0 10px 0 !important;
        background-color: rgba(0, 0, 0, 0.04);
        .wound-header {
          .expand-wound {
            transform: rotate(-180deg);
          }
        }
        /*
        .etiology-other {
          max-height: 0;
          min-height: 0;
          padding-top: 0 !important;
          opacity: 0;
          overflow: hidden;
        }
        */
        .wound-details {
          .wound-container {
            opacity: 1;
          }
        }
      }
      .select-field-label-top, .text-field-label-top, .text-area-label-top {
        .v-input__prepend-outer {
          font-size: 0.85em;
          margin: 0 0 0 2px;
          color: rgba(0, 0, 0, 0.68);
          // The following emulates a small label imbedded in the input box top
          padding: 0 2px;
          top: -5px;
          left: 6px;
          padding-bottom: 1px;
          position: absolute;
          z-index: 2;
          // background-image: linear-gradient(to bottom, transparent, transparent, white, white, white);
          display: inline-block;
          max-width: 86%;
          &::before {
            content: "";
            width: 100%;
            height: 3px;
            z-index: -1;
            background-color: white;
            position: absolute;
            top: 4px;
            left: 0;
          }
        }
      }
      .wound-header {
        align-items: center;
        min-height: 68.5px;
        .expand-wound {
          margin: 0 auto;
          width: 29px;
          height: 29px;
          font-size: 29px;
          svg {
            height: 29px;
          }
        }
        .v-icon:not(.expand-wound) {
          width: 22px;
          height: 22px;
        }
      }
      .etiology-other {
        transition: min-height 0.3s ease, max-height 0.3s ease, padding 0.3s ease, opacity 0.3s ease;
        padding: 6px 5px 0 5px;
        margin: 0 0 0 auto;
        max-height: 57px;
        opacity: 1;
        overflow: hidden;
        .v-input__prepend-outer {
          top: 1px;
        }
      }
      .wound-details {
        .wound-container {
          transition: max-height 0.3s ease, opacity 0.3s ease;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          .hundred-percent {
            width: 694px;
            max-width: 1078px
          }
          .v-input__append-inner {
            padding-right: 2px;
          }
        }
      }
      .v-card__actions, .row {
        padding: 7px 2px 0 2px !important;
        margin: 0 0 -10px 0 !important;
        > *:not(.v-btn) {
          padding: 0 3px 10px 3px !important;
          // line-height: 51px;
          .v-input__slot {
            padding: 0 9px !important;
            label.v-label {
              max-width: 100% !important;
              &.v-label--active {
                max-width: none !important;
              }
            }
          }
          span.v-icon {
            top: -1px;
          }
        }
        .v-btn.del-treatment {
          min-width: unset;
          max-width: 44px;
          margin-top: -6px;
          margin-left: -3px;
          .v-icon {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
